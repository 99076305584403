import React, { useEffect } from 'react';
// import { Helmet } from "react-helmet";
import './style.scss';
import { Link, useNavigate } from 'react-router-dom';

const NotificationLocked: React.FC = () => {


  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      navigate('/checkin/notification-success')
    }, 5000)
  });

  return (
    <div className='checkin-page checkin-notification'>
      <div className="bg-img">
        <img src="/images/checkin/ellipse-bg.png" alt="" />
      </div>
      <div className="content-block mw-1157">
        <div className="img-box">
          <img src="/images/checkin/fail-icon.png" alt="" />
        </div>
        <div className="title cl-F9D361">Sorry! Your account is locked</div>
        <div className="sum cl-fff">Please contact to admin to<br></br>unlock and play! </div>
        <Link to="/checkin-ai" className="btn btn-border-text-primary h-68">Return to home (5s)</Link>
      </div>
    </div>
  );
}

export default NotificationLocked;
