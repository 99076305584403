// import React, { useEffect, useState } from 'react';
// import { Helmet } from "react-helmet";
import { Link, useNavigate } from 'react-router-dom';
import './style.scss';
import { Like } from 'iconsax-react';
import { Button } from 'antd';
import { useLocation } from 'react-router-dom'

const CheckInEmail: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { message } = location.state

  const onReset = () => {
    navigate('/login/forgot-password')
  };

  return (
    <div className='login-page'>
      <div className="bg-img">
        <img src="/images/checkin/bg-checkin-op1.jpg" alt="" />
      </div>
      <div className="img-logo">
        <img src="/images/logo.png" alt="" />
      </div>
      <div className="content-block mw-464">
        <Link to="/login/forgot-password" className='btn-back'>Back</Link>

        <div className="title mb-24">Check Your Email</div>

        {/* <div className="sum t-fz-16">We've sent a password reset link to email ******c97@gmail.com. If you don't see it, check your spam or junk mail folder.</div> */}
        <div className="sum t-fz-16">{ message }</div>

        <div className="mb-0 btn-block">
          {/* <Link to="/login/new-password" className="btn t-btn-primary h-44"> */}
          <div className="btn t-btn-primary h-44">
            <div className="icon-svg">
              <Like variant="Bulk" />
            </div>
            <div className="text">
              I Understand
            </div>
          </div>
          {/* </Link> */}
          <div className="text-reset">
            Didn't receive the email?
            <Button className='btn-reset rs-btn' htmlType="button" onClick={onReset}>
              Resend
            </Button>
          </div>
        </div>
      </div>
    </div >
  );
}

export default CheckInEmail;
