import React, { useEffect, useRef, useState } from 'react'
import { Button, Flex, Form, FormInstance, FormProps, Input, Modal, notification } from 'antd'
import { AddSquare, Code, Gameboy, Magicpen } from 'iconsax-react'
import { mixPostV1 } from '../../../services/mixin'

const closeIcon = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M3.86312 3.86363C4.03189 3.6949 4.26077 3.60012 4.49942 3.60012C4.73806 3.60012 4.96694 3.6949 5.13572 3.86363L8.99942 7.72733L12.8631 3.86363C12.9461 3.77767 13.0455 3.7091 13.1553 3.66194C13.2651 3.61477 13.3832 3.58994 13.5027 3.5889C13.6222 3.58786 13.7407 3.61063 13.8513 3.65589C13.9619 3.70114 14.0624 3.76797 14.1469 3.85247C14.2314 3.93697 14.2982 4.03746 14.3435 4.14807C14.3887 4.25867 14.4115 4.37719 14.4104 4.49669C14.4094 4.61619 14.3846 4.73429 14.3374 4.84409C14.2902 4.95389 14.2217 5.0532 14.1357 5.13623L10.272 8.99993L14.1357 12.8636C14.2997 13.0334 14.3904 13.2607 14.3883 13.4967C14.3863 13.7327 14.2916 13.9584 14.1248 14.1253C13.9579 14.2921 13.7322 14.3868 13.4962 14.3888C13.2602 14.3909 13.0329 14.3002 12.8631 14.1362L8.99942 10.2725L5.13572 14.1362C4.96598 14.3002 4.73863 14.3909 4.50266 14.3888C4.26668 14.3868 4.04095 14.2921 3.87408 14.1253C3.70721 13.9584 3.61256 13.7327 3.61051 13.4967C3.60846 13.2607 3.69918 13.0334 3.86312 12.8636L7.72682 8.99993L3.86312 5.13623C3.69439 4.96745 3.59961 4.73857 3.59961 4.49993C3.59961 4.26128 3.69439 4.0324 3.86312 3.86363Z" fill="#6B7280"/> </svg>

interface Props {
  show: boolean
  storeId: number
  onModalClose: () => void
}

type DevicesType = {
  device_id?: string
  device_name?: string
}
type FieldType = {
  devices?: DevicesType[]
}

const DevicesOfStoreModal:React.FC<Props> = (props) => {
  const { show, storeId, onModalClose } = props

  useEffect(() => {
    if (show) {
      getDevices()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  // UPDATE DEVICES
  const formRef = useRef<FormInstance<FieldType>>(null)
  const onSubmitForm = () => {
    if (formRef && formRef.current) {
      formRef?.current?.submit()
    }
  }

  const [confirmLoading, setConfirmLoading] = useState(false)
  const onFinish: FormProps<FieldType>["onFinish"] = async (values: any) => {
    if (values.devices.length === 0){
      openNotification('error', 'Please add at least 1 device!')
      return
    }

    const devicesJSON = JSON.stringify(values.devices)
    const params = {
      store_id: storeId,
      array_devices: devicesJSON
    }

    setConfirmLoading(true)
    try {
      const res = await mixPostV1('/api/webAdmin/createAndUpdateDevicesOwner.php', params)

      if (res.status) {
        setConfirmLoading(false)
        openNotification()
        onModalClose()

        if (formRef?.current) {
          formRef.current.resetFields()
        }
      } else {
        setConfirmLoading(false)
        openNotification('error', res.message)
      }
    } catch (error) {
      openNotification('error', 'Something went wrong!')
    }
  }
  // END UPDATE DEVICES

  // GET DEVICES
  const getDevices = async () => {
    const params = {
      store_id: storeId,
    }
    try {
      const res = await mixPostV1('/api/webAdmin/gListDevicesOwnerByStore.php', params)
      if (res.status === 1) {
        const data: DevicesType[] = []

        res.data.map((item: DevicesType) => data.push({
          'device_id': item.device_id,
          'device_name': item.device_name,
        }))
        const formattedData: FieldType = { devices: data }

        if (formRef.current) {
          formRef.current.setFieldsValue(formattedData)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  // END GET DEVICES

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'Devices updated!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    });
  };

  return (
    <>
      { contextHolder }

      <Modal
        centered
        open={show}
        closeIcon={closeIcon}
        className='rs-modal w-687'
        rootClassName='rs-modal-root'
        footer={null}
        onCancel={onModalClose}
      >
        <div className="setting-content setting-content--update setting-device">
          <Flex vertical gap={16} style={{ width: '100%' }}>
            <div className='font-medium'>Devices</div>
            <Form
              ref={formRef}
              name="device-form"
              onFinish={onFinish}
              layout='vertical'
              autoComplete="off"
              className='rs-form form-gap-12'
            >
              <Form.List name="devices">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Flex
                        key={key}
                        gap={8}
                        align='center'
                      >
                        <Form.Item
                          {...restField}
                          name={[name, 'device_name']}
                          rules={[{required: true, message: 'This field must be filled!'}]}
                        >
                          <Input
                            placeholder="Device name"
                            className="rs-input"
                            prefix={<Gameboy size={18} variant="Bulk" />}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'device_id']}
                          rules={[{required: true, message: 'This field must be filled!'}]}
                        >
                          <Input
                            placeholder="Device ID"
                            className="rs-input"
                            prefix={<Code size={18} variant="Bulk" />}
                          />
                        </Form.Item>
                        {/* {
                          index === 0
                            ?
                              <Button
                                className={'rs-button button-magic-pen default-outlined'}
                                icon={<Magicpen size="20" variant="Bulk" />}
                              />
                            :
                          } */}
                        <Button
                          className={'rs-button button-magic-pen danger-outlined'}
                          icon={<Magicpen size="20" variant="Bulk" />}
                          onClick={() => remove(name)}
                        />
                      </Flex>
                    ))}

                    <Button
                      type='primary'
                      icon={<AddSquare size="20" variant="Bulk" />}
                      className='rs-button button-duplicate primary-outlined'
                      onClick={() => add()}
                    >Add new</Button>
                  </>
                )}
              </Form.List>
            </Form>

            <Flex justify='flex-end'>
              <Button
                type='primary'
                loading={confirmLoading}
                className='rs-button'
                onClick={onSubmitForm}
              >Update</Button>
            </Flex>
          </Flex>
        </div>
      </Modal>
    </>
  )
}

export default DevicesOfStoreModal
