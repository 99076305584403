import React, { useEffect, useState, useRef } from 'react'
import Layout from '../../components/layouts/Layout'
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock'
import { Link } from 'react-router-dom'
import { Button, DatePicker, Flex, GetProps, Spin } from 'antd'
import { Calendar, DocumentDownload, Home2 } from 'iconsax-react'
import './Dashboard.scss'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import PieChart1 from '../Checkin/PieChart1'
import PieChart2 from '../Checkin/PieChart2'
import MatchChart from './Components/MatchChart'
import MetricItem from './Components/MetricItem'
import { mixPostV1 } from '../../services/mixin'
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import DasTotalMatchModal from './Components/DasTotalMatchModal'
import DasTotalPayoutModal from './Components/DasTotalPayoutModal'

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;
dayjs.extend(customParseFormat);
const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  // Can not select days after today and today
  return current && current > dayjs().endOf('day');
};

const metricsFilter = [
  { id: 1, label: 'Today' },
  { id: 2, label: 'Yesterday' },
  // { id: 3, label: 'Monthly' },
  // { id: 4, label: 'Yearly' },
]

type genderType = {
  id: number,
  name: string,
  value: number,
  color: string,
}

type memberMatchType = {
  name: string,
  value: number,
  color: string,
}

type matchType = {
  name: string,
  value: number,
}

type DashboardType = {
  total_match: number,
  match_percent: number,
  total_payout: number,
  payout_percent: number,

  total_income: number,
  income_percent: number,
  total_outcome: number,
  outcome_percent: number,

  total_bonus: number,
  bonus_percent: number,
  total_profit: number,
  profit_percent: number,

  total_member: number,
  member_percent: number,
  total_member_active: number,
  member_active_percent: number,

  totalGender: genderType[],
  member_match: memberMatchType[],
  match: matchType[],
}

const ReportDashboard: React.FC = () => {
  const componentRef = useRef() as any;
  const handleDownloadPdf = () => {
    const input = componentRef.current;
    html2canvas(input, {
      scale: 2, // Tăng độ phân giải của canvas để đảm bảo chất lượng cao hơn
      useCORS: true, // Cho phép cross-origin images
    }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');

      // Tính toán kích thước của hình ảnh trên PDF
      const imgWidth = 210; // Chiều rộng của A4 là 210mm
      const pageHeight = 295; // Chiều cao của A4 là 295mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      // Chèn hình ảnh vào PDF và xử lý nếu hình ảnh dài hơn một trang
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save('download.pdf');
    });
  };

  const [metricFilterSelected, setMetricFilterSelected] = useState(1)
  const onMetricFilter = (id: number) => {
    setMetricFilterSelected(id)
    setSelectedDate(null)
  }
  const [dashboardData, setDashboardData] = useState<DashboardType>()
  const [loading, setLoading] = useState(false)
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    getData(metricFilterSelected)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricFilterSelected])

  // GET DATA BY DATE
  const formatDate = (date: any) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  const getDataByDate = async (date: any) => {
    if (!date) {
      setMetricFilterSelected(1)
      setSelectedDate(null)
    } else {
      setLoading(true)
      setMetricFilterSelected(-1)
      setSelectedDate(date)
    }

    try {
      const newDate = new Date(date)
      const res = await mixPostV1('/api/webAdmin/gReportByDate.php', {date_select: formatDate(newDate)})

      if (res.status === 1) {
        const result = res.data
        result.totalGender = totalGender(res.data.totalGender)
        result.member_match = memberMatch(res.data.member_match)
        result.match = matchConvert(res.data.charOfMatch)
        setDashboardData(result)
        setLoading(false)
      }
    } catch (error) { console.log(error) }
  }

  // GET DATA
  const getData = async (type: number = 1) => {
    if (type === -1) return

    setLoading(true)
    const totalMatchPayout = await getTotalMatchPayout(type)
    const incomeOutcome = await getIncomeOutcome(type)
    const bonusProfit = await getBonusProfit(type)
    const member = await getMember(type)
    const match = await getMatch(type)
    setLoading(false)

    const metricsData = {
      ...dashboardData,
      ...bonusProfit,
      ...incomeOutcome,
      ...totalMatchPayout,
      ...member,
      ...match,
    }

    setDashboardData(metricsData)
  } // END GET DATA

  // GET BONUS AND PROFIT
  const getBonusProfit = async (type: number = 1) => {
    try {
      const res = await mixPostV1('/api/webAdmin/gReportBonusAndProfitByStore.php', { type: type })

      if (res.status === 1) {
        return res.data
      }
    } catch (error) { console.log(error) }
  } // END GET BONUS AND PROFIT

  // GET INCOME & OUTCOME
  const getIncomeOutcome = async (type: number = 1) => {
    try {
      const res = await mixPostV1('/api/webAdmin/gReportIncomeAndOutcomeByStore.php', { type: type })

      if (res.status === 1) {
        return res.data
      }
    } catch (error) { console.log(error) }
  } // END GET INCOME & OUTCOME

  // GET MATCH & PAYOUT
  const getTotalMatchPayout = async (type: number = 1) => {
    try {
      const res = await mixPostV1('/api/webAdmin/gReportMatchAndPayoutByStore.php', { type: type })

      if (res.status === 1) {
        return res.data
      }
    } catch (error) { console.log(error) }
  } // END GET MATCH & PAYOUT

  // GET MEMBER
  const getMember = async (type: number = 1) => {
    try {
      const res = await mixPostV1('/api/webAdmin/gReportMemberByStore.php', { type: type })

      if (res.status === 1) {
        const result = res.data
        result.totalGender = totalGender(res.data.totalGender)
        result.member_match = memberMatch(res.data.member_match)
        return result
      }
    } catch (error) { console.log(error) }
  }
  const totalGender = (data: any) => {
    const result:any = []
    data.map((item: any) => result.push({
      ...item,
      name: item.gender_name,
      value: item.total,
      color: item.gender_name === 'Male' ? '#blue' : '#orange'
    }))
    return result
  }
  const memberMatch = (data: any) => {
    const result:any = []
    data.map((item: any) => result.push({
      ...item,
      name: item.label,
      value: item.total,
      color: memberMatchColor(item.label)
    }))
    return result
  }
  const memberMatchColor = (name: string) => {
    let result = ''

    switch (name) {
      case '0-24':
      case '0~24':
        result = '#cl4'
        break;

      case '25-49':
      case '25~49':
        result = '#cl2'
        break;

      case '50-74':
      case '50~74':
        result = '#cl3'
        break;

      case '75-99':
      case '75~99':
        result = '#cl1'
        break;

      case '>=100':
      case '>100':
        result = '#cl5'
        break;

      default:
        result = '#cl4'
        break;
    }

    return result
  }
  // END GET MEMBER

  // GET MATCH
  const getMatch = async (type: number = 1) => {
    try {
      const res = await mixPostV1('/api/webAdmin/gReportChartOfMatchByStore.php', { type: type })

      if (res.status === 1) {
        return { match: matchConvert(res.data) }
      }
    } catch (error) { console.log(error) }
  }
  const matchConvert = (data: any) => {
    const result:any = []
    data.map((item: any) => result.push({
      ...item,
      name: item.label,
      value: item.total,
    }))
    return result
  }
  // END GET MATCH

  // METRIC ITEM vsText COMBINATION
  const vsTextCombine = () => {
    let result = ''

    switch (metricFilterSelected) {
      case 1:
        result = 'yesterday'
        break;

      case 2:
        result = 'last week'
        break;

      case 3:
        result = 'last month'
        break;

      case 4:
        result = 'last year'
        break;

      default:
        result = 'previous day'
        break;
    }

    return result
  }// END METRIC ITEM vsText COMBINATION

  const [showDasTotalMatchModal, setShowDasTotalMatchModal] = useState(false)
  const [showDasTotalPayoutModal, setShowDasTotalPayoutModal] = useState(false)

  return (
    <Layout contentClassName="report-dashboard-content">
      <BreadcrumbBlock
        items={[
          { title: <Flex align='center' gap={16}><Home2 variant="Bulk" /><Link to="/">CMD Tech</Link></Flex>, },
          { title: 'Report', },
          { title: 'Dashboard', },
        ]}
        title='Dashboard'
        description=''
      />

      <div className="chart-show">
        <div className="left">
          <div className="filter">
            {
              metricsFilter.map(item => (
                <div
                  key={item.id}
                  className={`filter-item ${metricFilterSelected === item.id ? 'active' : ''}`}
                  onClick={() => onMetricFilter(item.id)}
                >{ item.label }</div>
              ))
            }
          </div>
        </div>
        <div className="right">
          <div className="filter">
            <DatePicker
              value={selectedDate}
              placeholder='Select date'
              className='rs-date-picker'
              popupClassName='rs-date-picker-dropdown'
              suffixIcon={<Calendar size={18} variant="Bulk"/>}
              disabledDate={disabledDate}
              onChange={getDataByDate}
            />
            <Flex align='center' gap={12}>
              {/* <Flex vertical className='text-right' style={{ minWidth: '120px' }}>
                <span className="text-idle" style={{ lineHeight: '20px'}}>Report created</span>
                <span className="text-idle" style={{ lineHeight: '20px'}}>by Jackson (12312)</span>
              </Flex> */}
              <Button
                icon={<DocumentDownload size={20} variant="Bulk"/>}
                className="rs-button"
                onClick={handleDownloadPdf}
              >Export PDF</Button>
            </Flex>
          </div>
        </div>
      </div>

      <Spin spinning={loading}>
        <div className="chart-show">
          <div className="left">
            <div className="list-show metric-list">
              <MetricItem
                title='Total Match'
                quantity={dashboardData?.total_match}
                type={Number(dashboardData?.match_percent) >= 0 ? 'increase' : 'decrease' || 'increase'}
                percent={dashboardData?.match_percent}
                vsText={vsTextCombine()}
                onClick={() => setShowDasTotalMatchModal(true)}
              />
              <MetricItem
                title='Total Cashout'
                quantity={dashboardData?.total_payout}
                type={Number(dashboardData?.payout_percent) >= 0 ? 'increase' : 'decrease' || 'increase'}
                percent={dashboardData?.payout_percent}
                vsText={vsTextCombine()}
                onClick={() => setShowDasTotalPayoutModal(true)}
              />
            </div>
            <div className="list-show metric-list">
              <MetricItem
                title='Income (USD)'
                quantity={dashboardData?.total_income}
                type={Number(dashboardData?.income_percent) >= 0 ? 'increase' : 'decrease' || 'increase'}
                percent={dashboardData?.income_percent}
                vsText={vsTextCombine()}
              />
              <MetricItem
                title='Outcome (USD)'
                quantity={dashboardData?.total_outcome}
                type={Number(dashboardData?.outcome_percent) >= 0 ? 'increase' : 'decrease' || 'increase'}
                percent={dashboardData?.outcome_percent}
                vsText={vsTextCombine()}
              />
            </div>
            <div className="list-show metric-list">
              <MetricItem
                title='Total Bonus (USD)'
                quantity={dashboardData?.total_bonus}
                type={Number(dashboardData?.bonus_percent) >= 0 ? 'increase' : 'decrease' || 'increase'}
                percent={dashboardData?.bonus_percent}
                vsText={vsTextCombine()}
              />
              <MetricItem
                title='Profit (USD)'
                quantity={dashboardData?.total_profit}
                type={Number(dashboardData?.profit_percent) >= 0 ? 'increase' : 'decrease' || 'increase'}
                percent={dashboardData?.profit_percent}
                vsText={vsTextCombine()}
              />
            </div>
          </div>
          <div className="right" ref={componentRef}>
            <MatchChart
              title='Match'
              data={dashboardData?.match}
            />
          </div>
        </div>
      </Spin>

      <Spin spinning={loading}>
        <div className="pie-chart-block">
          <div className="left">
            <div className="list-show">
              <div className="one-item">
                <div className="title">Members</div>
                <div className="number fz-32">{ dashboardData?.total_member }</div>
                <div className="compare">
                  <div className="percent-block">
                    {/* <div className="icon-svg">
                      <ArrowDownOutlined />
                    </div> */}
                    {
                      Number(dashboardData?.member_percent) >= 0
                        ? <ArrowUpOutlined style={{ color: '#027A48'}} />
                        : <ArrowDownOutlined style={{ color: '#B42318'}} />
                    }
                    <div
                      className="percent"
                      style={{ color: Number(dashboardData?.member_percent) >= 0 ? '#027A48' : '#B42318' }}
                    >{ dashboardData?.member_percent }%</div>
                  </div>
                  <div className="text">vs {vsTextCombine()}</div>
                </div>
              </div>

              <div className="one-item mt-24">
                <div className="title">Members active</div>
                <div className="number fz-32">{ dashboardData?.total_member_active }</div>
                {/* <div className="compare">
                  <div className="percent-block">
                    {
                      Number(dashboardData?.member_active_percent) >= 0
                        ? <ArrowUpOutlined style={{ color: '#027A48'}} />
                        : <ArrowDownOutlined style={{ color: '#B42318'}} />
                    }
                    <div
                      className="percent"
                      style={{ color: Number(dashboardData?.member_active_percent) >= 0 ? '#027A48' : '#B42318' }}
                    >{ dashboardData?.member_active_percent }%</div>
                  </div>
                  <div className="text">vs {vsTextCombine()}</div>
                </div> */}
              </div>

            </div>
          </div>
          <div className="right">
            <div className="one-chart">
              <PieChart1
                data={dashboardData?.totalGender}
              />
            </div>
            <div className="one-chart">
              <PieChart2
                data={dashboardData?.member_match}
              />
            </div>
          </div>
        </div>
      </Spin>
      <DasTotalMatchModal
        isShow={showDasTotalMatchModal}
        typeParam={metricFilterSelected}
        onPopupClose={() => setShowDasTotalMatchModal(false)}
      />

      <DasTotalPayoutModal
        isShow={showDasTotalPayoutModal}
        typeParam={metricFilterSelected}
        onPopupClose={() => setShowDasTotalPayoutModal(false)}
      />
    </Layout>
  )
}

export default ReportDashboard
