import React, { useEffect, useState } from 'react'
import './RandomInputItem.scss'
import { Button, Input } from 'antd'
import { Magicpen } from 'iconsax-react'

interface Props {
  text?: string
  showDelete?: boolean
  onChange?: (name: string) => void
  onDelete?: () => void
}

const RandomInputItem: React.FC<Props> = (props) => {
  const {  text = '', showDelete = true, onChange, onDelete, } = props
  const [inputValue, setInputValue] = useState(text)

  useEffect(() => {
    setInputValue(text)
  }, [text])

  const deleteItem = () => {
    if (onDelete) {
      onDelete()
    }
  }

  const handleChange = (e: any) => {
    if (onChange) {
      onChange(e.target.value)
    }
  }

  return (
    <div className="random-input-item">
      <Input
        defaultValue={inputValue}
        placeholder='Input anything'
        className='rs-input'
        readOnly={!showDelete}
        onBlur={handleChange}
      />
      {
        showDelete &&
          <Button
            icon={<Magicpen size={20} variant="Bulk" />}
            className='rs-button primary-outline'
            onClick={() => deleteItem()}
          />
      }
    </div>
  )
}

export default RandomInputItem
