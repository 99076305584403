import React from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

const CheckoutForm = (props) => {
  const {onChange} = props
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, token } = await stripe.createToken(cardElement);

    if (error) {
      onChange('error')
    } else {
      const last4 = token.card.last4;
      const tokenId = token.id
      onChange(token)
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button id='tripebtnaddv2' className='btn-hidden' type="submit" disabled={!stripe}>
        Add
      </button>
    </form>
  );
};

export default CheckoutForm;
