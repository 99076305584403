import React from 'react';
// import { Helmet } from "react-helmet";
import './style.scss';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Input, Form } from 'antd';

type FieldType = {
  name?: string;
  phone?: number;
  dob?: string;
};

const MemberRegister: React.FC = () => {


  const navigate = useNavigate()

  const onFinish = (values: FieldType) => {
    navigate('/checkin/checkin-otp-staff')
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='checkin-page checkin-index'>
      <div className="bg-img">
        <img src="/images/checkin/bg-checkin-op1.jpg" alt="" />
      </div>
      <div className="content-block mw-708">
        <div className="title mb-24">Identity verified</div>

        <div className="sum">
          It looks like you are new here
        </div>

        <Form
          className="form-block"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item<FieldType>
            label="Phone Number"
            name="dob"
            rules={[{ required: true }]}
            className='mb-24'
          >
            <div className="input-block addon-after-input">
              <Input placeholder="Your phone number" />
            </div>
          </Form.Item>

          <div className="btn-block ctrl-grid-2">
            <Link to="/check-login" className="btn btn-border-text-primary h-68">Cancel</Link>
            <Form.Item>
              <div className="mb-0">
                <Button className="btn t-btn-primary h-68" type="primary" htmlType="submit">
                  <div className="text">
                    Verify
                  </div>
                </Button>
              </div>
            </Form.Item>
          </div>
        </Form>

      </div>
    </div>
  );
}

export default MemberRegister;
