interface Props {
  hourinhour?: any
  changeHourinhour?: any
  editDisabled?: boolean
}
const Hour: React.FC<Props> = (props) => {
  const { hourinhour, changeHourinhour, editDisabled = false, } = props

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Chuyển đổi giá trị nhập vào thành số
    const value = parseInt(event.target.value)

    if (isNaN(value)) {
      return
    }
    // Kiểm tra nếu giá trị nhập vào nằm trong giới hạn 1 - 24
    if (value < 1) {
      changeHourinhour(1)
    } else if (value > 24) {
      changeHourinhour(24)
    } else {
      changeHourinhour(value)
    }
  }

  return (
    <div className="content collapse-shift">
      <span>
        Player can match every
      </span>
      <span className="ctrl-input-number">
        <input
          type="number"
          value={hourinhour}
          onChange={handleChange}
          className="rs-input"
          disabled={editDisabled}
        />
      </span>
      <span>
        hour.
      </span>
    </div>
  )
}

export default Hour
