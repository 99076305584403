import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Drawer,
  Flex,
  Form,
  FormInstance,
  FormProps,
  Input,
  Select,
  SelectProps,
  Upload,
  notification,
} from "antd";
import { mixGetV1, mixPostV1 } from "../../services/mixin";
import UploadImage from "../../components/Upload/UploadImage";
import { ArrowSquareDown, Category2 } from "iconsax-react";

const { TextArea } = Input;

const closeIcon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {" "}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.86312 3.86363C4.03189 3.6949 4.26077 3.60012 4.49942 3.60012C4.73806 3.60012 4.96694 3.6949 5.13572 3.86363L8.99942 7.72733L12.8631 3.86363C12.9461 3.77767 13.0455 3.7091 13.1553 3.66194C13.2651 3.61477 13.3832 3.58994 13.5027 3.5889C13.6222 3.58786 13.7407 3.61063 13.8513 3.65589C13.9619 3.70114 14.0624 3.76797 14.1469 3.85247C14.2314 3.93697 14.2982 4.03746 14.3435 4.14807C14.3887 4.25867 14.4115 4.37719 14.4104 4.49669C14.4094 4.61619 14.3846 4.73429 14.3374 4.84409C14.2902 4.95389 14.2217 5.0532 14.1357 5.13623L10.272 8.99993L14.1357 12.8636C14.2997 13.0334 14.3904 13.2607 14.3883 13.4967C14.3863 13.7327 14.2916 13.9584 14.1248 14.1253C13.9579 14.2921 13.7322 14.3868 13.4962 14.3888C13.2602 14.3909 13.0329 14.3002 12.8631 14.1362L8.99942 10.2725L5.13572 14.1362C4.96598 14.3002 4.73863 14.3909 4.50266 14.3888C4.26668 14.3868 4.04095 14.2921 3.87408 14.1253C3.70721 13.9584 3.61256 13.7327 3.61051 13.4967C3.60846 13.2607 3.69918 13.0334 3.86312 12.8636L7.72682 8.99993L3.86312 5.13623C3.69439 4.96745 3.59961 4.73857 3.59961 4.49993C3.59961 4.26128 3.69439 4.0324 3.86312 3.86363Z"
      fill="#6B7280"
    />{" "}
  </svg>
);

type LabelRender = SelectProps["labelRender"];
const customSelectedLabel: LabelRender = (props) => {
  const { label, value } = props;

  if (value && label) {
    return (
      <Flex align="center" gap={8}>
        <Category2 size={18} variant="Bulk" style={{ flexShrink: 0 }} />
        <span>{label}</span>
      </Flex>
    );
  }
  return (
    <Flex align="center" gap={8}>
      <Category2 size={18} variant="Bulk" style={{ flexShrink: 0 }} />
      <span>Type</span>
    </Flex>
  );
};

interface Props {
  isShow?: boolean;
  onPopupClose?: () => void;
  onSuccess?: () => void;
}

type FieldType = {
  name?: string;
  address?: string;
  type?: string;
  owner?: string;
  avatar?: string;
  // matchType?: any;
};

const RequestNewStorePopup: React.FC<Props> = (props) => {
  const { isShow = false, onPopupClose, onSuccess } = props;
  const [open, setOpen] = useState(isShow);

  useEffect(() => {
    setOpen(isShow);

    if (isShow) {
      getStoreTypes();
    }
  }, [isShow]);

  const onClose = () => {
    setOpen(false);
    if (onPopupClose) {
      onPopupClose();
    }
  };

  // FORM SUBMIT
  const formRef = useRef<FormInstance<FieldType>>(null);
  const onSubmitForm = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  // FORM SUBMIT SUCCESSFULLY
  const [confirmLoading, setConfirmLoading] = useState(false);
  const handleOk = async (values: any) => {
    // setConfirmLoading(true)

    const params = {
      store_name: values.name,
      address: values.address,
      avatar: values.avatar || "",
      type_id: values.type,
    };

    try {
      const res = await mixPostV1(
        "/api/webAdmin/createRequestOwnerStore.php",
        params
      );

      if (res.status === 1) {
        setConfirmLoading(false);
        openNotification();
        onClose();

        if (onSuccess) {
          onSuccess();
        }
        if (formRef.current) {
          formRef.current.resetFields();
        }
      } else {
        setConfirmLoading(false);
        openNotification("error", res.message);
      }
    } catch (error) {
      setConfirmLoading(false);
      openNotification("error", "Oops, something went wrong!");
    }

    // setTimeout(() => {
    //   setConfirmLoading(false)
    //   onClose()
    //   openNotification()
    //   if (formRef.current) {
    //     formRef.current.resetFields()
    //   }
    //   if (onSuccess) {
    //     onSuccess()
    //   }
    // }, 1500)
  };
  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    handleOk(values);
  };

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (
    type: "success" | "info" | "warning" | "error" = "success",
    message = "Request success!"
  ) => {
    api[type]({
      message: message,
      placement: "bottomRight",
      className: "single-message",
    });
  };

  // POPUP FOOTER
  const Footer = (
    <>
      <Button className="rs-button default-outlined" onClick={onClose}>
        Cancel
      </Button>
      <Button
        type="primary"
        loading={confirmLoading}
        block
        className="rs-button"
        onClick={onSubmitForm}
      >
        Request
      </Button>
    </>
  );

  // GET SERVICE TYPES
  const [serviceTypes, setServiceTypes] = useState([] as any);
  const getStoreTypes = async () => {
    try {
      const res = await mixGetV1("/api/webAdmin/gComboTypeStore.php");

      if (res.status === 1) {
        const result: any = [];
        res.data.map((item: any) =>
          result.push({
            ...item,
            value: item.type_id,
            label: item.type_name,
          })
        );
        setServiceTypes(result);
      }
    } catch (error) {}
  };
  // END GET SERVICE TYPES

  return (
    <>
      {contextHolder}

      <Drawer
        title="Request new store"
        rootClassName="rs-drawer w-338 footer-with-2-button"
        open={open}
        footer={Footer}
        closeIcon={closeIcon}
        onClose={onClose}
      >
        <Form
          ref={formRef}
          onFinish={onFinish}
          autoComplete="off"
          className="rs-form"
          layout="vertical"
          requiredMark={false}
        >
          <Form.Item name="avatar" style={{ marginBlockEnd: 10 }}>
            <Flex vertical gap={10} align="center" className="avatar-uploader">
              <span className="title-12 text-light">Store Logo</span>
              {/* <Upload
                name="avatar"
                listType="picture-circle"
                showUploadList={false}
              >
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.6665 26.6665C10.0249 26.6684 8.4403 26.0644 7.21633 24.9704C5.99235 23.8764 5.21507 22.3692 5.0334 20.7377C4.85172 19.1061 5.27843 17.4649 6.23177 16.1284C7.18511 14.7919 8.59804 13.8542 10.1999 13.4949C9.73641 11.3334 10.1506 9.07627 11.3513 7.22013C12.552 5.36399 14.4409 4.06085 16.6024 3.59738C18.7639 3.13392 21.021 3.54809 22.8771 4.74879C24.7333 5.9495 26.0364 7.83837 26.4999 9.99988H26.6665C28.7331 9.99781 30.7268 10.7637 32.2604 12.1488C33.7941 13.534 34.7584 15.4396 34.9661 17.4957C35.1738 19.5518 34.61 21.6118 33.3844 23.2757C32.1587 24.9395 30.3585 26.0886 28.3332 26.4999M24.9999 21.6665L19.9999 16.6665M19.9999 16.6665L14.9999 21.6665M19.9999 16.6665V36.6665" stroke="#9CA3AF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> </svg>
              </Upload> */}
              <UploadImage
                onChange={(file) => {
                  formRef.current?.setFieldValue("avatar", file);
                }}
              />
            </Flex>
          </Form.Item>

          <Form.Item<FieldType>
            label="Store name"
            name="name"
            rules={[{ required: true, message: "This field must be filled!" }]}
            style={{ marginBlockEnd: 10 }}
          >
            <Input placeholder="Store name" className="rs-input" />
          </Form.Item>

          <Form.Item<FieldType>
            label="Address"
            name="address"
            rules={[{ required: true, message: "This field must be filled!" }]}
            style={{ marginBlockEnd: 10 }}
          >
            <TextArea placeholder="Address" className="rs-input" rows={7} />
          </Form.Item>

          <Form.Item<FieldType>
            label="Type"
            name="type"
            rules={[{ required: true, message: "This field must be filled!" }]}
            style={{ marginBlockEnd: "10px" }}
            initialValue={""}
          >
            <Select
              options={serviceTypes}
              placeholder="Type"
              popupMatchSelectWidth={false}
              suffixIcon={<ArrowSquareDown size={20} variant="Bulk" />}
              labelRender={customSelectedLabel}
              className="rs-select"
              popupClassName="rs-select-dropdown"
            />
          </Form.Item>

          {/* <Form.Item<FieldType>
            label="Match Start"
            name="matchType"
            rules={[{ required: true, message: 'This field must be filled!' }]}
            style={{ marginBlockEnd: 0 }}
          >
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Match start"
              // onChange={handleChange}
              options={[
                { value: 20, label: 20 },
                { value: 40, label: 40 },
                { value: 60, label: 60 },
                { value: 80, label: 80 },
                { value: 100, label: 100 },
              ]}
              className='rs-select'
            />
          </Form.Item> */}
        </Form>
      </Drawer>
    </>
  );
};

export default RequestNewStorePopup;
