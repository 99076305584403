import React, { useEffect, useState } from 'react'
import { Button, Flex, Input, Modal, PaginationProps, Select, Table, TableColumnsType, notification } from 'antd'
import { ArrowSquareDown, Clock, SearchZoomIn1 } from 'iconsax-react'
import './AddMember.scss'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
// import tableData from './playersTableData.json'
import { mixPostV1 } from '../../../services/mixin'
import {IMAGE_URL} from '../../../env'

interface Props {
  isShow?: boolean
  buttonText?: string,
  onPopupClose?: () => void
  onAddMember?: (members: any) => void
}

const closeIcon = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M3.86312 3.86363C4.03189 3.6949 4.26077 3.60012 4.49942 3.60012C4.73806 3.60012 4.96694 3.6949 5.13572 3.86363L8.99942 7.72733L12.8631 3.86363C12.9461 3.77767 13.0455 3.7091 13.1553 3.66194C13.2651 3.61477 13.3832 3.58994 13.5027 3.5889C13.6222 3.58786 13.7407 3.61063 13.8513 3.65589C13.9619 3.70114 14.0624 3.76797 14.1469 3.85247C14.2314 3.93697 14.2982 4.03746 14.3435 4.14807C14.3887 4.25867 14.4115 4.37719 14.4104 4.49669C14.4094 4.61619 14.3846 4.73429 14.3374 4.84409C14.2902 4.95389 14.2217 5.0532 14.1357 5.13623L10.272 8.99993L14.1357 12.8636C14.2997 13.0334 14.3904 13.2607 14.3883 13.4967C14.3863 13.7327 14.2916 13.9584 14.1248 14.1253C13.9579 14.2921 13.7322 14.3868 13.4962 14.3888C13.2602 14.3909 13.0329 14.3002 12.8631 14.1362L8.99942 10.2725L5.13572 14.1362C4.96598 14.3002 4.73863 14.3909 4.50266 14.3888C4.26668 14.3868 4.04095 14.2921 3.87408 14.1253C3.70721 13.9584 3.61256 13.7327 3.61051 13.4967C3.60846 13.2607 3.69918 13.0334 3.86312 12.8636L7.72682 8.99993L3.86312 5.13623C3.69439 4.96745 3.59961 4.73857 3.59961 4.49993C3.59961 4.26128 3.69439 4.0324 3.86312 3.86363Z" fill="#6B7280"/> </svg>

interface DataType {
  key: React.Key;
  appId?: string,
  name?: string,
  phone?: string,
  face?: string,
  timer?: string,
  avatar?: string,
}

const customPrevNext: PaginationProps['itemRender'] = (_, type, originalElement) => {
  if (type === 'prev') {
    return (
      <Flex align='center' gap={8}>
        <ArrowLeftOutlined />
        <span>Previous</span>
      </Flex>
    )
  }
  if (type === 'next') {
    return (
      <Flex align='center' gap={8}>
        <span>Next</span>
        <ArrowRightOutlined />
      </Flex>
    )
  }
  return originalElement
}

const AddMemberModal:React.FC<Props> = (props) => {
  const { isShow = false, buttonText = 'Add player', onPopupClose, onAddMember } = props
  const [open, setOpen] = useState(isShow);

  useEffect(() => {
    setOpen(isShow)

    if (isShow) {
      getLevels()
      getItems()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow])

  const onClose = () => {
    setOpen(false);
    if (onPopupClose) {
      onPopupClose();
    }
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: 'App ID',
      dataIndex: 'appId',
      key: 'appId',
    },
    {
      title: 'Customer name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Phone number',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Member Face',
      dataIndex: 'face',
      key: 'face',
      render: (text, record) => <Flex align='center' gap={12}><img width="40" src={`${IMAGE_URL}${record.avatar}`} alt={record.name} className="avatar" /><span>{ text }</span></Flex>
    },
  ]
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [selectedCustomers, setSelectedCustomers] = useState([] as any)
  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any) => {
    setSelectedRowKeys(newSelectedRowKeys)
    setSelectedCustomers(selectedRows)
  };
  const rowSelection = {
    fixed: true,
    selectedRowKeys,
    columnWidth: 68,
    onChange: onSelectChange,
  };

  const [loading, setLoading] = useState(false)
  const addPlayer = () => {
    if (selectedCustomers.length > 50) {
      openNotification('error', 'Send up to 50 guests')
      return
    }
    setLoading(true)
    setTimeout(() => {
      setSelectedRowKeys([])
      setLoading(false)

      if (onAddMember) {
        onAddMember(selectedCustomers)
      }
    }, 1000)
  }

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'Message has been sent!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    });
  }

  // GET LIST
  const [items, setItems] = useState({
    list: [],
    total: 0,
  })
  const [tableLoading, setTableLoading] = useState(false)

  const [params, setParams] = useState<{
    customer_type?: number,
    limit: number,
    page_number: number,
    searchKey?: string,
    roleSearch?: string,
    levelSearch?: string,
    searchShift?: string,
  }>({
    customer_type: 1,
    limit: 10,
    page_number: 1,
  })

  const getItems = async () => {
    setTableLoading(true)

    try {
      const res = await mixPostV1('/api/webAdmin/gListCustomerByStore.php', params)

      if (res.status === 1) {
        const result:any = []
        // eslint-disable-next-line array-callback-return
        res.data.map((item: any, index: number) => {
          result.push({
            ...item,
            key: index,
            appId: item.app_id,
            name: item.customer_name,
            phone: item.phone_number,
            level: item.level,
            role: item.role_name,
            match: item.match_amount,
            cashout: item.cash_out,
            avatar: item.avatar || '/images/avatar.png',
            face: item.date_checkin,
            statusLock: item.is_locked,
          })
          // initLevels(item.level)
        })
        setItems({
          ...items,
          list: result,
          total: res.total,
        })
        setTableLoading(false)
        // initLevels(res.data)
      }
    } catch (error) {}
  }

  useEffect(() => {
    getItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const onPageChange = (page: number) => {
    setParams({
      ...params,
      page_number: page,
    })
  }

  const onTableChange = (data: any) => {
    setParams({
      ...params,
      page_number: data.current,
      limit: data.pageSize,
    })
  }
  // END GET LIST

  // LEVELS
  const [levels, setLevels] = useState([] as any)
  const getLevels = async () => {
    try {
      const res = await mixPostV1('/api/webAdmin/gComboLevelSetting.php', {})
      if (res.status === 1) {
        const data = res.data.map((item: any) => ({
          value: item.level_name,
          label: item.level_name,
        }))
        data.sort((a: any, b:any) => a.value - b.value)
        setLevels(data)
      }
    } catch (error) {

    }
  }
  const onFilterLevel = (value: any) => {
    if (value) {
      setParams({
        ...params,
        page_number: 1,
        levelSearch: value
      })
    } else {
      setParams({
        ...params,
        page_number: 1,
        levelSearch: ''
      })
    }
  }

  // SHIFT CHANGE
  const [shiftTimeout, setShiftTimeout] = useState<any>(null)
  const onShiftFilter = (query: string) => {
    setParams({
      ...params,
      page_number: 1,
      searchShift: query
    })
  }
  const onShiftEnter = (e: any)  => {
    onShiftFilter(e.target.value)
  }
  const onShiftChange = (e: any) => {
    clearTimeout(shiftTimeout)
    const timeout = setTimeout(() => {
      onShiftFilter(e.target.value)
    }, 250)
    setShiftTimeout(timeout)
  }
  // END SHIFT CHANGE

  // SEARCH
  const [searchTimeout, setSearchTimeout] = useState<any>(null)
  const onSearch = (query: string) => {
    setParams({
      ...params,
      page_number: 1,
      searchKey: query
    })
  }
  const onEnter = (e: any)  => {
    onSearch(e.target.value)
  }
  const onChange = (e: any) => {
    clearTimeout(searchTimeout)
    const timeout = setTimeout(() => {
      onSearch(e.target.value)
    }, 250)
    setSearchTimeout(timeout)
  }
  // END SEARCH

  return (
    <>
      { contextHolder }
      <Modal
        centered
        open={open}
        closeIcon={closeIcon}
        footer={null}
        className='rs-modal add-member-modal'
        rootClassName='rs-modal-root'
        onCancel={onClose}
      >
        <div className="table-filter-block">
          <Input
            placeholder="Search by name, phone"
            prefix={<SearchZoomIn1 size={20} variant="Bulk" />}
            className="rs-input w-349"
            allowClear
            onPressEnter={(e) => onEnter(e)}
            onChange={(e) => onChange(e)}
          />

          <Input
            placeholder="Shift"
            allowClear
            prefix={<Clock size={20} variant="Bulk" />}
            className="rs-input w-214"
            type="Number"
            onPressEnter={(e) => onShiftEnter(e)}
            onChange={(e) => onShiftChange(e)}
          />

          <Select
            defaultValue={null}
            options={levels}
            placeholder="Level"
            allowClear={params.levelSearch !== ''}
            popupMatchSelectWidth={false}
            suffixIcon={<ArrowSquareDown size={20} variant="Bulk" />}
            className="rs-select w-214"
            onChange={onFilterLevel}
          />

          <Button
            loading={loading}
            disabled={selectedRowKeys.length === 0}
            className='rs-button light-outlined w-186 mis-auto'
            onClick={addPlayer}
          > { buttonText } { selectedRowKeys.length > 0 && `(${selectedRowKeys.length})`}</Button>
        </div>

        <Table
          columns={columns}
          dataSource={items.list}
          className="rs-table table-text-nowrap match-height"
          rowSelection={rowSelection}
          loading={tableLoading}
          scroll={{
            x: true,
          }}
          showSorterTooltip={false}
          tableLayout="fixed"
          pagination={{
            current: params.page_number,
            pageSize: params.limit,
            position: ['bottomCenter'],
            showSizeChanger: true,
            showTitle: false,
            total: items.total,
            itemRender: customPrevNext,
            onChange: (e) => onPageChange(e),
          }}
          onChange={onTableChange}
        />
      </Modal>
    </>
  )
}

export default AddMemberModal
