import React from 'react'
import { ArrowDownOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Flex } from 'antd'
import { AddSquare, Gameboy, Magicpen } from 'iconsax-react'
import './Players.scss'
import GamePlayer from './Player'
import RandomInputItem from './RandomInputItemV2'

interface Props {
  title?: string,
  type?: string,
  list?: Array<any>,
  className?: string,
  onAddPlayer?: () => void,
  onRemove?: (id: any) => void,
  onRemoveAll?: () => void,
  onChange?: any,
  onClose?: () => void
}

const Players: React.FC<Props> = (props) => {
  const { title, type = 'lucky-game', list, className = '', onRemoveAll, onAddPlayer, onRemove, onChange, onClose } = props

  const removeAll = () => {
    if (onRemoveAll) {
      onRemoveAll()
    }
  }

  const onAdd = () => {
    if (onAddPlayer) {
      onAddPlayer()
    }
  }

  const removeItem = (id: any) => {
    if (onRemove) {
      onRemove(id)
    }
  }
  const changePlayer = (textx: any, id: any) => {
    const temp = [] as any
    list?.map(val => {
      let text = val.text
      if (val.id === id) {
        text = textx
      }
      temp.push({
        ...val,
        text
      })
      return true
    })
    onChange(temp)
  }

  return (
    <>
      <div className={`game-players ${className}`}>
        <div className="game-players__header">
          <Gameboy size={20} color='#999999' variant="Bulk" />
          <Flex align='center' gap={4}>
            <span>{ title }</span>
            <ArrowDownOutlined />
          </Flex>
          <CloseOutlined
            className='neutral-gray-500 mis-auto'
            onClick={onClose}
          />
        </div>

        <div className={`game-players__content ${type}`}>
          {
            type === 'lucky-game' && list?.length
              ? list.map(item => (
                  <GamePlayer
                    key={item.id}
                    id={item.id}
                    avatar={item.avatar}
                    name={item.name}
                    onDelete={() => removeItem(item.id)}
                  />
                ))
              : null
          }
          {
            type === 'random-game' && list?.length
              ? list.map(item => (
                  <RandomInputItem
                    key={item.id}
                    text={item.text}
                    onDelete={() => removeItem(item.id)}
                    onChange={(e: any) => changePlayer(e, item.id)}
                  />
                ))
              : null
          }
        </div>

        <div className="game-players__footer">
          <Button
            icon={<Magicpen size={20} variant="Bulk" />}
            className='rs-button danger-outlined size-sm'
            onClick={removeAll}
          >
            Clear all
          </Button>
          <Button
            icon={<AddSquare size={20} variant="Bulk" />}
            className='rs-button light-outlined size-sm'
            onClick={onAdd}
          >
            { type === 'lucky-game' ? 'Add player' : 'Add input' }
          </Button>
        </div>
      </div>

      <div
        className={`game-players-backdrop ${className}`}
        onClick={onClose}
      />
    </>
  )
}

export default Players
