import { Button, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './ChooseStoreToGetDataRealTime.scss'
import ChooseStoreToGetDataRealTimeGrid from './components/ChooseStoreToGetDataRealTimeGrid'
import ChooseStoreToGetDataRealTimeItem from './components/ChooseStoreToGetDataRealTimeItem'
import { mixPostV1 } from '../../services/mixin'
import PaymentModal from '../../components/Modal/PaymentModal'

const ChooseStoreToGetDataRealTime:React.FC = () => {
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage();
  const user = JSON.parse(localStorage.getItem('user') || '{}')
  const [store, setStore] = React.useState([])
  const [storeId, setStoreId] = useState(null as any)

  const getStore = async () => {
    const res = await mixPostV1('/api/webAdmin/gComboStoreByOwner.php', {owner_id: user.id})
    if (res.status === 1) {
      setStore(res.data)
      const stores = res.data.map((item: any) => ({
        ...item,
        key: item.store_id.toString(),
        label: item.store_name,
      }))
      localStorage.setItem('stores', JSON.stringify(stores))
    }
  }
  useEffect(() => { getStore() }, [])
  const choseStore = async (val: number, typeId: any) => {
    const param = {
      owner_id: user.id,
      store_id: val,
    }
    // const currentStore: any = store.find((item: any) => item.store_id === val)
    const res = await mixPostV1('/api/webAdmin/createTokenAdmin.php', param)
    if (res.status === 1) {
      localStorage.setItem('token', res.token)
      localStorage.setItem('storeId', val.toString())
      localStorage.setItem('storeTypeId', typeId.toString())
      window.location.href = '/'
    } else {
      messageApi.open({
        type: 'error',
        content: res.message,
      });
    }
  }

  // MODAL PAYMENT
  const [showPaymentModal, setShowPaymentModal] = useState(false)

  return (
    <>
      {contextHolder}

      <div className="choose-store-page">
        <div className="choose-store-page-container">
          <figure className="logo">
            <img src="/images/logo.png" alt="" />
          </figure>
          <Button
            className='rs-button default-outlined go-back'
            onClick={() => navigate('/login')}
          >Back</Button>
          <div className="title">Choose your store to get data real-time</div>
          <ChooseStoreToGetDataRealTimeGrid>
            {
              store.map((item: any) => (
                <ChooseStoreToGetDataRealTimeItem
                  key={item.store_id}
                  name={item.store_name}
                  address={item.address}
                  storeId={item.store_id}
                  disabled={item.status !== 1}
                  onSelect={(e: number) => choseStore(e, item.type_id)}
                  onShowModal={(e) => {setShowPaymentModal(true); setStoreId(e)}}
                />
              ))
            }
          </ChooseStoreToGetDataRealTimeGrid>
        </div>
      </div>

      <PaymentModal
        storeId={storeId}
        isShow={showPaymentModal}
        onModalClose={() => { setShowPaymentModal(false) }}
        onModalSubmit={() => { setShowPaymentModal(false); getStore() }}
      />
    </>
  )
}

export default ChooseStoreToGetDataRealTime
