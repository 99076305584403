import React, { useEffect, useState } from 'react';
// import { DatePicker, Drawer, Flex, Select, Tabs, TabsProps } from 'antd';
// import { ArrowSquareDown, Calendar2, Clock, Gift } from 'iconsax-react';
import { Drawer, Tabs, TabsProps } from 'antd';
import { Gift } from 'iconsax-react';
import TabVoucher from './TabVoucher';
// import TabNormal from './TabNormal';
import { mixPostV1 } from '../../services/mixin';

const closeIcon = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M3.86312 3.86363C4.03189 3.6949 4.26077 3.60012 4.49942 3.60012C4.73806 3.60012 4.96694 3.6949 5.13572 3.86363L8.99942 7.72733L12.8631 3.86363C12.9461 3.77767 13.0455 3.7091 13.1553 3.66194C13.2651 3.61477 13.3832 3.58994 13.5027 3.5889C13.6222 3.58786 13.7407 3.61063 13.8513 3.65589C13.9619 3.70114 14.0624 3.76797 14.1469 3.85247C14.2314 3.93697 14.2982 4.03746 14.3435 4.14807C14.3887 4.25867 14.4115 4.37719 14.4104 4.49669C14.4094 4.61619 14.3846 4.73429 14.3374 4.84409C14.2902 4.95389 14.2217 5.0532 14.1357 5.13623L10.272 8.99993L14.1357 12.8636C14.2997 13.0334 14.3904 13.2607 14.3883 13.4967C14.3863 13.7327 14.2916 13.9584 14.1248 14.1253C13.9579 14.2921 13.7322 14.3868 13.4962 14.3888C13.2602 14.3909 13.0329 14.3002 12.8631 14.1362L8.99942 10.2725L5.13572 14.1362C4.96598 14.3002 4.73863 14.3909 4.50266 14.3888C4.26668 14.3868 4.04095 14.2921 3.87408 14.1253C3.70721 13.9584 3.61256 13.7327 3.61051 13.4967C3.60846 13.2607 3.69918 13.0334 3.86312 12.8636L7.72682 8.99993L3.86312 5.13623C3.69439 4.96745 3.59961 4.73857 3.59961 4.49993C3.59961 4.26128 3.69439 4.0324 3.86312 3.86363Z" fill="#6B7280" /> </svg>

interface Props {
  id?: number
  isShow?: boolean
  onPopupClose?: () => void
}

const onChange = (key: string) => {
  console.log(key);
};

const HistoryPopup: React.FC<Props> = (props) => {
  const { id, isShow = false, onPopupClose } = props
  const [open, setOpen] = useState(isShow)
  const [items, setItems] = useState([] as any)

  const tabNav: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <div className="title-tab">
          <div className="icon-svg"><Gift size="16" variant="Bulk" /></div>
          <div className="text t-fz-16px">With voucher</div>
        </div>
      ),
      children: (<TabVoucher list={items} />),
    },
    // {
    //   key: '2',
    //   label: (
    //     <div className="title-tab">
    //       <div className="text t-fz-16px">Normal</div>
    //     </div>
    //   ),
    //   children: (<TabNormal />),
    // },
  ];

  useEffect(() => {
    setOpen(isShow)

    if (isShow) {
      setParams({
        ...params,
        customer_id: Number(id),
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow, id])

  const onClose = () => {
    setOpen(false);
    if (onPopupClose) {
      onPopupClose();
    }
  };

  // GET LIST
  const [params, setParams] = useState<{
    customer_id?: number,
    date_search?: any,
    shift_search?: string,
  }>({})

  const getItems = async () => {
    const res = await mixPostV1('/api/webAdmin/gHistoryCashoutVoucherByCustomer.php', params)

    if (res.status === 1) {
      const result:any = []
      res.data.map((item: any) => result.push({
        ...item
      }))
      setItems(result)
    }
  }

  useEffect(() => {
    getItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])
  // END GET LIST

  return (
    <>
      <Drawer
        title="History"
        rootClassName="rs-drawer w-338 popup-history"
        open={open}
        closeIcon={closeIcon}
        onClose={onClose}
      >
        {/* <div className="filter-top">
          <DatePicker
            className="t-picker-ctrl"
            suffixIcon={<Calendar2 size={20} variant="Bulk" />}
            onChange={(date, dateString) => setParams({ ...params, date_search: dateString })}
          />

          <Select
            defaultValue={'shift'}
            options={[
              { value: 'shift', label: 'Shift' },
            ]}
            labelRender={({ label }) => label ? <Flex align='center' gap={8}><Clock size={20} variant="Bulk" />{label}</Flex> : <Flex align='center' gap={8}><Clock size={20} variant="Bulk" />Select</Flex>}
            placeholder="Shift"
            popupMatchSelectWidth={false}
            suffixIcon={<ArrowSquareDown size={20} variant="Bulk" />}
            className="rs-select"
          />
        </div> */}

        <Tabs defaultActiveKey="1" items={tabNav} onChange={onChange} />
      </Drawer>
    </>
  )
}

export default HistoryPopup
