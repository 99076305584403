import React, { useEffect, useRef, useState } from 'react'
import { Button, Flex, Form, FormInstance, FormProps, Input, Modal, Select, SelectProps, notification } from 'antd'
import { ArrowSquareDown, DiscountCircle, Message, ReceiptDiscount, Send2, SmsEdit } from 'iconsax-react'
import { mixPostV1 } from '../../services/mixin'

const { TextArea } = Input

const closeIcon = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M3.86312 3.86363C4.03189 3.6949 4.26077 3.60012 4.49942 3.60012C4.73806 3.60012 4.96694 3.6949 5.13572 3.86363L8.99942 7.72733L12.8631 3.86363C12.9461 3.77767 13.0455 3.7091 13.1553 3.66194C13.2651 3.61477 13.3832 3.58994 13.5027 3.5889C13.6222 3.58786 13.7407 3.61063 13.8513 3.65589C13.9619 3.70114 14.0624 3.76797 14.1469 3.85247C14.2314 3.93697 14.2982 4.03746 14.3435 4.14807C14.3887 4.25867 14.4115 4.37719 14.4104 4.49669C14.4094 4.61619 14.3846 4.73429 14.3374 4.84409C14.2902 4.95389 14.2217 5.0532 14.1357 5.13623L10.272 8.99993L14.1357 12.8636C14.2997 13.0334 14.3904 13.2607 14.3883 13.4967C14.3863 13.7327 14.2916 13.9584 14.1248 14.1253C13.9579 14.2921 13.7322 14.3868 13.4962 14.3888C13.2602 14.3909 13.0329 14.3002 12.8631 14.1362L8.99942 10.2725L5.13572 14.1362C4.96598 14.3002 4.73863 14.3909 4.50266 14.3888C4.26668 14.3868 4.04095 14.2921 3.87408 14.1253C3.70721 13.9584 3.61256 13.7327 3.61051 13.4967C3.60846 13.2607 3.69918 13.0334 3.86312 12.8636L7.72682 8.99993L3.86312 5.13623C3.69439 4.96745 3.59961 4.73857 3.59961 4.49993C3.59961 4.26128 3.69439 4.0324 3.86312 3.86363Z" fill="#6B7280"/> </svg>

type LabelRender = SelectProps['labelRender']
const customSelectedLabel: LabelRender = (props) => {
  const { label } = props;

  if (label) {
    return <Flex align='center' gap={8}><DiscountCircle size={20} variant="Bulk"/><span>{label}</span></Flex>;
  }
  return <Flex align='center' gap={8}><DiscountCircle size={20} variant="Bulk"/><span>Voucher Game</span></Flex>;
}

interface Props {
  customers?: Array<any>
  isShow?: boolean
  onPopupClose?: () => void
  onSend?: () => void
}

type FieldType = {
  message?: string;
  voucher?: string;
}

const SendMessageModal:React.FC<Props> = (props) => {
  const { isShow = false, customers, onPopupClose, onSend } = props
  const [open, setOpen] = useState(isShow)
  const [sendType, setSendType] = useState('message')

  const onSendTypeChange = (e: any) => {
    setSendType(e.target.value)
  }

  const customerIdArr: any = customers?.map((item: any) => ({ customer_id: item.customer_id }))

  useEffect(() => {
    setOpen(isShow)

    if (isShow) {
      checkVoucher()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow])

  const onClose = () => {
    setOpen(false);
    if (onPopupClose) {
      onPopupClose();
    }
  };

  // FORM SUBMIT
  const formRef = useRef<FormInstance<FieldType>>(null);

  const onSubmitForm = () => {
    if (formRef.current) {
      formRef.current.submit()
    }
  }

  // SEND MESSAGE
  const sendMessage = async (message: any) => {
    const params = {
      array_customer: JSON.stringify(customerIdArr),
      content_sms: message
    }

    try {
      const res = await mixPostV1('/api/webAdmin/storeGameSendMessage.php', params)

      if (res.status === 1) {
        setConfirmLoading(false)
        onClose()
        openNotification()

        if (formRef.current) {
          formRef.current.resetFields()
        }

        if (onSend) {
          onSend()
        }
      } else {
        setConfirmLoading(false)
        openNotification('error', res.message)
      }
    } catch (error) { }
  } // END SEND MESSAGE

  // SEND VOUCHER
  const sendVoucher = async (voucherId: number) => {
    const params = {
      array_customer: JSON.stringify(customerIdArr),
      voucher_id: voucherId
    }
    try {
      const res = await mixPostV1('/api/webAdmin/storeGameSendMessage.php', params)

      if (res.status === 1) {
        setConfirmLoading(false)
        onClose()
        openNotification()

        if (formRef.current) {
          formRef.current.resetFields()
        }

        if (onSend) {
          onSend()
        }
      } else {
        setConfirmLoading(false)
        openNotification('error', res.message)
      }
    } catch (error) { }
  }

  // FORM SUBMIT SUCCESSFULLY
  const [confirmLoading, setConfirmLoading] = useState(false)
  const handleOk = (values: any) => {
    setConfirmLoading(true)

    if (sendType === 'message') {
      sendMessage(values.message)
    }
    if (sendType === 'voucher') {
      sendVoucher(values.voucher)
    }
    // setTimeout(() => {
    //   setConfirmLoading(false)
    //   onClose()
    //   openNotification()

    //   if (formRef.current) {
    //     formRef.current.resetFields()
    //   }

    //   if (onSend) {
    //     onSend()
    //   }
    // }, 1500)
  }
  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    handleOk(values)
  };

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'Message has been sent!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    });
  }

  // CHECK VOUCHER
  const [vouchers, setVouchers] = useState([] as any)
  const checkVoucher = async () => {
    const params = {
      array_customer: JSON.stringify(customerIdArr),
    }
    try {
      const res = await mixPostV1('/api/webAdmin/storeGameCheckVoucher.php', params)
      if (res.status === 0) {
        const result: any = res.data.map((item: any) => ({
          value: item.voucher_id,
          label: item.voucher_name,
        }))
        setVouchers(result)
      }
    } catch (error) { }
  } // END CHECK VOUCHER

  return (
    <>
      { contextHolder }

      <Modal
        centered
        open={open}
        closeIcon={closeIcon}
        footer={null}
        confirmLoading={confirmLoading}
        className='rs-modal w-360'
        rootClassName='rs-modal-root'
        onCancel={onClose}
      >
        <Flex vertical gap={20}>
          <div className="text-center" style={{ paddingBlockStart: 'clamp(12px, 2vw, 20px)'}}>
            <SmsEdit size={64} variant="Bulk" />
          </div>

          <Form
            ref={formRef}
            onFinish={onFinish}
            autoComplete="off"
            className="rs-form"
            layout="vertical"
            requiredMark={false}
            style={{ gap: '12px' }}
          >
            <div className="text-center text-light title-24 fw-700">Send { customers?.length || 0 } SMS</div>

            <Flex
              vertical
              gap={8}
              onChange={onSendTypeChange}
            >
              <label className='custom-radio' style={{ width: '100%' }}>
                <input type="radio" name="gender" value="message" defaultChecked/>
                <Message variant="Bulk" size={20} />
                <span>Send message</span>
                <span className="custom-radio-icon"></span>
              </label>
              <label className='custom-radio' style={{ width: '100%' }}>
                <input type="radio" name="gender" value="voucher" />
                <ReceiptDiscount variant="Bulk" size={20} />
                <span>Send Voucher</span>
                <span className="custom-radio-icon"></span>
              </label>
            </Flex>

            {
              sendType === 'message' &&
                <Form.Item<FieldType>
                  label="Send message"
                  name="message"
                  rules={[{ required: true, message: 'This field must be filled!' }]}
                >
                  <TextArea
                    placeholder="Your brand sms content"
                    className="rs-input"
                    rows={5}
                    count={{
                      show: true,
                      max: 260,
                    }}
                  />
                </Form.Item>
            }

            {
              sendType === 'voucher' &&
                <Form.Item<FieldType>
                  label="Send Voucher"
                  name="voucher"
                  rules={[{ required: true, message: 'Please select!' }]}
                  initialValue={'voucher-game'}
                >
                  <Select
                    options={vouchers}
                    placeholder="Select Staff"
                    popupMatchSelectWidth={false}
                    suffixIcon={<ArrowSquareDown size={20} variant="Bulk" />}
                    className="rs-select"
                    popupClassName='rs-select-dropdown'
                    labelRender={customSelectedLabel}
                  />
                </Form.Item>
            }
          </Form>

          <Flex vertical gap={12}>
            <Button
              icon={<Send2 size={20} variant="Bulk" />}
              block
              type='primary'
              className='rs-button'
              loading={confirmLoading}
              onClick={onSubmitForm}
            >Send</Button>

            <Button
              block
              className='rs-button'
              onClick={onClose}
            >Cancel</Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  )
}

export default SendMessageModal
