import React from 'react'
import './PaymentMethodGrid.scss'

interface Props {
  className?: string
  children?: React.ReactNode
}

const PaymentMethodGrid:React.FC<Props> = (props) => {
  const { children } = props

  return (
    <div className="ovh-y-all-card">
      <div className="payment-method-grid">
        { children }
      </div>
    </div>
  )
}

export default PaymentMethodGrid
