import React, { useEffect, useState } from 'react'
import Layout from '../../components/layouts/Layout'
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock'
import { Link } from 'react-router-dom'
import { Button, Flex, Input, notification, PaginationProps, Table, TableColumnsType, Tabs, TabsProps } from 'antd'
import { Clock, DocumentDownload, Home2, Man, SearchZoomIn1, UserAdd, Woman } from 'iconsax-react'
import { ArrowLeftOutlined, ArrowRightOutlined, CheckOutlined, DeleteOutlined, } from '@ant-design/icons'
// import tableData from './staff-manage-table-data.json'
import CreateNewStaffPopup from './CreateNewStaffPopup'
import EditStaffPopup from './EditStaffPopup'
import DeleteModal from '../../components/Modal/DeleteModal'
import { mixPostV1 } from '../../services/mixin'
import './Manage.scss'
import * as XLSX from 'xlsx'

interface DataType {
  key: React.Key;
  staffId: number,
  staff: string,
  sex: string,
  phoneNumber: string,
  clockIn: string,
  dob: string,
  address: string,
  is_player: number,
}

const pencil = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clipPath="url(#clip0_1265_4186)"> <path d="M14.167 2.49993C14.3859 2.28106 14.6457 2.10744 14.9317 1.98899C15.2176 1.87054 15.5241 1.80957 15.8337 1.80957C16.1432 1.80957 16.4497 1.87054 16.7357 1.98899C17.0216 2.10744 17.2815 2.28106 17.5003 2.49993C17.7192 2.7188 17.8928 2.97863 18.0113 3.2646C18.1297 3.55057 18.1907 3.85706 18.1907 4.16659C18.1907 4.47612 18.1297 4.78262 18.0113 5.06859C17.8928 5.35455 17.7192 5.61439 17.5003 5.83326L6.25033 17.0833L1.66699 18.3333L2.91699 13.7499L14.167 2.49993Z" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/> </g> <defs> <clipPath id="clip0_1265_4186"> <rect width="20" height="20" fill="white"/> </clipPath> </defs> </svg>

const customPrevNext: PaginationProps['itemRender'] = (_, type, originalElement) => {
  if (type === 'prev') {
    return (
      <Flex align='center' gap={8}>
        <ArrowLeftOutlined />
        <span>Previous</span>
      </Flex>
    )
  }
  if (type === 'next') {
    return (
      <Flex align='center' gap={8}>
        <span>Next</span>
        <ArrowRightOutlined />
      </Flex>
    )
  }
  return originalElement
}

// CHECK ROLE = 'USER'
const user = JSON.parse(localStorage.getItem('user') || '{}')
const permission = user.permission
const staffRole = permission ? permission.find((item: any) => item.function_id === 5) : null

const StaffManage: React.FC = () => {
  const [showCreateNewStaffPopup, setShowCreateNewStaffPopup] = useState(false)
  const [showEditStaffPopup, setShowEditStaffPopup] = useState(false)
  const [showDeleteStaffPopup, setShowDeleteStaffPopup] = useState(false)
  const [selectedId, setSelectedId] = useState<number>()
  const [searchType] = useState('phone-number')
  const [currentTab, setCurrentTab] = useState('2')

  const columns: TableColumnsType<DataType> = [
    {
      title: '',
      key: 'actions',
      fixed: 'left',
      className: 'actions-sticky actions-sticky--left',
      render: (record) => (
        <Flex align='center' gap={4} style={ currentTab === '1' ? { opacity: 0, pointerEvents: 'none' } : {}}>
          <Button
            icon={<DeleteOutlined />}
            className="rs-button delete"
            disabled={staffRole && staffRole.is_delete === 0}
            // title={staffRole && staffRole.is_delete === 0 ? 'You don\'t have permission' : ''}
            onClick={()=> {
              setSelectedId(record.id)
              setShowDeleteStaffPopup(true)
            }}
          />
          <Button
            icon={pencil}
            className="rs-button edit"
            disabled={staffRole && staffRole.is_edit === 0}
            onClick={() => {
              setSelectedId(record.id)
              setShowEditStaffPopup(true)
            }}
          />
        </Flex>
      ),
      width: 132
      // hidden: currentTab === '1'
    },
    {
      title: 'Staff ID',
      dataIndex: 'staffId',
      key: 'staffId',
    },
    {
      title: 'Staff',
      dataIndex: 'staff',
      key: 'staff',
      render: (value) => (
        <div style={{ minWidth: 214, maxWidth: 300, whiteSpace: 'normal' }}>{ value }</div>
      )
    },
    {
      title: 'Sex',
      dataIndex: 'sex',
      key: 'sex',
      render: (val) => (
        <>
          {
            val.toLowerCase() === 'female'
              ?
                <Woman variant="Bulk" size={20} />
              :
                <Man variant="Bulk" size={20} />
          }

        </>
      )
    },
    {
      title: 'Phone number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: currentTab === '1' ? 'Clock-in' : '',
      dataIndex: 'clockIn',
      key: 'clockIn',
      render: (text) => (
        currentTab === '1'
          ? <span>{ text }</span>
          : ''
      ),
      width: 140,
      // hidden: currentTab === '2',
    },
    {
      title: 'DOB',
      dataIndex: 'dob',
      key: 'dob',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      render: (text) => (
        <div className="text-nowrap" style={{maxWidth: '220px'}}>{ text }</div>
      )
    },
    {
      title: 'Player',
      dataIndex: 'is_player',
      key: 'is_player',
      render: (text) => (
        text !== 0 &&
          <div className="text-center">
            <CheckOutlined style={{ color: '#027A48', fontSize: 24 }} />
          </div>
      )
    },
  ]

  // GET LIST
  const [items, setItems] = useState({
    list: [],
    total: 0,
  })
  const [tableLoading, setTableLoading] = useState(false)

  const [params, setParams] = useState<{
    limit: number,
    page_number: number,
    staff_type: number,
    searchPhoneNumber?: string,
    searchStaffName?: string,
    searchShift?: string,
  }>({
    limit: 10,
    page_number: 1,
    staff_type: 1,
  })

  const getItems = async () => {
    if (currentTab === '1') {
      getHistory()
    } else {
      getAllStaff()
    }
  }

  const getHistory = async () => {
    setTableLoading(true)
    const res = await mixPostV1('/api/webAdmin/gListStaffByStore.php', params)

    if (res.status === 1) {
      const result:any = []
      res.data.map((item: any, index: number) => result.push({
        ...item,
        key: index,
        staffId: item.id,
        staff: item.staff_name,
        sex: item.gender_name,
        phoneNumber: item.phone_number,
        clockIn: item.clockin,
        dob: item.birthday,
      }))
      setItems({
        ...items,
        list: result,
        total: res.total,
      })
      setTableLoading(false)
    }
  }

  const getAllStaff = async () => {
    setTableLoading(true)
    const res = await mixPostV1('/api/webAdmin/gListDistinctStaffByStore.php', params)

    if (res.status === 1) {
      const result:any = []
      res.data.map((item: any) => result.push({
        ...item,
        key: item.id,
        staffId: item.id,
        staff: item.staff_name,
        sex: item.gender_name,
        phoneNumber: item.phone_number,
        // clockIn: item.clockin,
        dob: item.birthday,
      }))
      setItems({
        ...items,
        list: result,
        total: res.total,
      })
      setTableLoading(false)
    }
  }

  useEffect(() => {
    getItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const onPageChange = (page: number) => {
    setParams({
      ...params,
      page_number: page,
    })
  }

  const onTableChange = (data: any) => {
    setParams({
      ...params,
      page_number: data.current,
      limit: data.pageSize,
    })
  }
  // END GET LIST

  // SEARCH
  const [searchTimeout, setSearchTimeout] = useState<any>(null)
  const onSearch = (query: string) => {
    if (searchType === 'phone-number') {
      setParams({
        ...params,
        page_number: 1,
        searchPhoneNumber: query,
        searchStaffName: '',
      })
    }
    if (searchType === 'staff-name') {
      setParams({
        ...params,
        page_number: 1,
        searchPhoneNumber: '',
        searchStaffName: query
      })
    }
  }
  const onEnter = (e: any)  => {
    onSearch(e.target.value)
  }
  const onChange = (e: any) => {
    clearTimeout(searchTimeout)
    const timeout = setTimeout(() => {
      onSearch(e.target.value)
    }, 250)
    setSearchTimeout(timeout)
  }
  // END SEARCH

  // SHIFT FILTER
  const [shiftTimeout, setShiftTimeout] = useState<any>(null)
  const onShiftFilter = (query: string) => {
    setParams({
      ...params,
      page_number: 1,
      searchShift: query
    })
  }
  const onShiftEnter = (e: any)  => {
    onShiftFilter(e.target.value)
  }
  const onShiftChange = (e: any) => {
    clearTimeout(shiftTimeout)
    const timeout = setTimeout(() => {
      onShiftFilter(e.target.value)
    }, 250)
    setShiftTimeout(timeout)
  }
  // END SHIFT FILTER

  // EXPORT EXCEL
  const getItemsByExcel = async () => {
    const customParams = {
      ...params,
      page_number: 1,
      limit: 10000,
    }
    let res: any

    try {
      if (currentTab === '1') {
        res = await mixPostV1('/api/webAdmin/gListStaffByStore.php', customParams)
      } else {
        res = await mixPostV1('/api/webAdmin/gListDistinctStaffByStore.php', params)
      }

      if (res.status === 1) {
        if (res.data.length > 0) {
          formattedDataExcel(res.data)
        } else {
          openNotification('error', 'There is no data to export!')
        }
      } else {
        openNotification('error', 'Oops, something went wrong!')
      }
    } catch (error) {
      openNotification('error', 'Oops, something went wrong!')
    }
  }
  const formattedDataExcel = (data: any) => {
    const formattedData = data.map((item: any) => {
      return currentTab === '1' ? historyFormatted(item) : allStaffFormatted(item)
    })
    exportExcel(formattedData)
  }
  const exportExcel = (convertData: any): boolean => {
    try {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData)
      const workbook: XLSX.WorkBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Danh sách')

      const excelBuffer: ArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' })

      const saveAsExcel = (buffer: ArrayBuffer, fileName: string): void => {
        const data: Blob = new Blob([buffer], { type: 'application/octet-stream' })
        const link: HTMLAnchorElement = document.createElement('a')
        link.href = window.URL.createObjectURL(data)
        link.download = fileName
        link.click()
      }

      saveAsExcel(excelBuffer, `${"Staff list"}.xlsx`)
      return true // Export successful
    } catch (error) {
      console.error('Error exporting Excel:', error)
      return false // Export failed
    }
  }
  const allStaffFormatted = (item: any) => {
    return {
      'Staff ID': item.id,
      'Staff': item.staff_name,
      'Sex': item.gender_name,
      'Phone Number': item.phone_number,
      'DOB': item.birthday,
      'Address': item.address,
    }
  }
  const historyFormatted = (item: any) => {
    return {
      'Staff ID': item.id,
      'Staff': item.staff_name,
      'Sex': item.gender_name,
      'Phone Number': item.phone_number,
      'Clock In': item.clockin,
      'DOB': item.birthday,
      'Address': item.address,
    }
  }
  // END EXPORT EXCEL

  // TABS
  const tabs: TabsProps['items'] = [
    {
      key: '2',
      label: (
        <div className="title-tab-setting">
          <div className="text t-fz-16">All staff</div>
        </div>
      ),
    },
    {
      key: '1',
      label: (
        <div className="title-tab-setting">
          <div className="text t-fz-16">Check in</div>
        </div>
      ),
    },
  ]
  const onTabChange = (key: string) => {
    setCurrentTab(key)
    setParams({
      ...params,
      searchShift: '',
    })
  }
  // END TABS

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'Staff created!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    });
  };

  return (
    <>
      { contextHolder }

      <Layout contentClassName="staff-manage-content">
        <BreadcrumbBlock
          items={[
            { title: <Flex align='center' gap={16}><Home2 variant="Bulk" /><Link to="/">CMD Tech</Link></Flex>, },
            { title: 'Staff', },
            { title: 'Manage', },
          ]}
          title='Manage'
          description=''
        />

        <div className="table-filter-block">
          <div className="left">
            <Input
              placeholder="Search by name or phone number"
              prefix={<SearchZoomIn1 size={20} variant="Bulk" />}
              allowClear
              className="rs-input table-filter-block__search"
              onPressEnter={(e) => onEnter(e)}
              onChange={(e) => onChange(e)}
            />

            {
              currentTab === '1' && (
                <Input
                  placeholder="Shift"
                  prefix={<Clock size={20} variant="Bulk" />}
                  allowClear
                  className="rs-input w-120"
                  type='number'
                  maxLength={1}
                  max={9}
                  onPressEnter={(e) => onShiftEnter(e)}
                  onChange={(e) => onShiftChange(e)}
                />
              )
            }
          </div>

          <div className="right">
            <Button
              icon={<DocumentDownload size={20} variant="Bulk"/>}
              className="rs-button"
              onClick={getItemsByExcel}
            >Export</Button>

            <Button
              icon={<UserAdd size={20} variant="Bulk"/>}
              className="rs-button"
              disabled={staffRole && staffRole.is_create === 0}
              onClick={() => setShowCreateNewStaffPopup(true)}
            >Create new staff</Button>
          </div>
        </div>

        <Tabs
          activeKey={currentTab}
          items={tabs}
          className='rs-tabs rs-tabs--no-tab-content'
          onChange={onTabChange}
        />

        <Table
          columns={columns}
          dataSource={items.list}
          className="rs-table table-text-nowrap match-height"
          loading={tableLoading}
          scroll={{
            x: true,
          }}
          pagination={{
            current: params.page_number,
            pageSize: params.limit,
            position: ['bottomCenter'],
            showSizeChanger: true,
            showTitle: false,
            total: items.total,
            itemRender: customPrevNext,
            onChange: (e) => onPageChange(e),
          }}
          onChange={onTableChange}
        />

        <CreateNewStaffPopup
          isShow={showCreateNewStaffPopup}
          onPopupClose={() => setShowCreateNewStaffPopup(false)}
          onSuccess={getItems}
        />

        <EditStaffPopup
          id={selectedId}
          isShow={showEditStaffPopup}
          onPopupClose={() => setShowEditStaffPopup(false)}
          onSuccess={getItems}
        />

        <DeleteModal
          isShow={showDeleteStaffPopup}
          content='Are you sure you want to delete this Staff?'
          message='Staff deleted!'
          // onModalClose={() => setShowDeleteStaffPopup(false)}
          // onModalSubmit={() => setShowDeleteStaffPopup(false)}
          deleteApi='/api/webAdmin/deleteStaffById.php'
          deleteId={selectedId}
          deleteKey='staff_id'
          onModalClose={() => setShowDeleteStaffPopup(false)}
          onModalSubmit={() => {
            getItems()
            setShowDeleteStaffPopup(false)
          }}
        />
      </Layout>
    </>
  )
}

export default StaffManage
