import React from 'react'
import { Link } from 'react-router-dom'
import './styles.scss'
import { Flex } from 'antd'

const PrivacyPolicy:React.FC = () => {
  return (
    <div className="privacy-policy-page">
      <div className="container">
        <div className="text-center">
          <Link to={'/'} className="app-widget__logo">
            <img src="/images/logo.svg" alt="CMDTech" className="default-logo"/>
          </Link>
        </div>

        <div className="text-center">
          <h1>Privacy Policy</h1>
          <small className='text-[#8E8E8E]'>Last Updated: 1/1/2024</small>
        </div>

        <Flex vertical gap={12}>
          <h3>1. Introduction</h3>
          <div>Welcome to CMD TECH Game Room Management System. This privacy policy outlines how we collect, use, and protect your personal information when you use our services and visit our website.</div>
        </Flex>

        <Flex vertical gap={12}>
          <h3>2. Consent</h3>
          <div>By using our services and providing us with your information, you consent to the collection, use, and sharing of your information as described in this policy.</div>
        </Flex>

        <Flex vertical gap={12}>
          <h3>3. Information Collection</h3>
          <div>We collect information you provide directly to us, such as when you register for our services, enter your phone number, or use a credit card for payments. This information may include, but is not limited to, your name, phone number, and credit card details.</div>
        </Flex>

        <Flex vertical gap={12}>
          <h3>4. Use of Information</h3>
          <div>
            Your information is used to:
            <ul>
              <li>Process transactions and provide our services.</li>
              <li>Send marketing communications and updates about our services.</li>
            </ul>
          </div>
        </Flex>

        <Flex vertical gap={12}>
          <h3>5. Third-Party Service Providers</h3>
          <div>We may share your information with third-party service providers to facilitate our services, process payments, or perform other related business activities.</div>
        </Flex>

        <Flex vertical gap={12}>
          <h3>6. Data Security</h3>
          <div>We implement reasonable measures to protect your personal information, but we cannot guarantee its absolute security. Please be aware that no method of transmission over the Internet or electronic storage is 100% secure.</div>
        </Flex>

        <Flex vertical gap={12}>
          <h3>7. User Responsibilities</h3>
          <div>It is your responsibility to protect your personal information and credit card details. We are not responsible for any loss or damage arising from your failure to safeguard your information.</div>
        </Flex>

        <Flex vertical gap={12}>
          <h3>8. Limitation of Liability</h3>
          <div>
            We will not be liable for any indirect, incidental, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:
            <ul>
              <li>Process transactions and provide our services.</li>
              <li>Send marketing communications and updates about our services.</li>
            </ul>
          </div>
        </Flex>

        <Flex vertical gap={12}>
          <h3>9. Changes to This Privacy Policy</h3>
          <div>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</div>
        </Flex>

        <Flex vertical gap={12}>
          <h3>10. Contact Us</h3>
          <div>If you have any questions about this Privacy Policy, please contact us at cmdtech2024@gmail.com.</div>
        </Flex>
      </div>
    </div>
  )
}

export default PrivacyPolicy
