import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './style.scss';
import { Magicpen, ProfileCircle } from 'iconsax-react';
import { Button, Input, notification } from 'antd';
import { mixPostV1 } from '../../services/mixin';


const ForgotPassword: React.FC = () => {
  const navigator = useNavigate()
  const [query, setQuery] = useState('')

  const sendReset = async () => {
    console.log(query, ' ===')
    if (query === '') {
      openNotification('error', 'Please type your username or phone number!')
      return false
    }

    try {
      const res = await mixPostV1('/api/webAdmin/sendMailForgotPassword.php', { phone_number: query })

      if (res.status === 1) {
        openNotification('success', 'Oops, something went wrong!')
        navigator('/login/check-email', { state: { message: res.message }})
      } else {
        openNotification('error', res.message)
      }
    } catch (error) {
      openNotification('error', 'Oops, something went wrong!')
    }
  }

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = '' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    });
  };

  return (
    <>
      { contextHolder }

      <div className='login-page'>
        <div className="bg-img">
          <img src="/images/checkin/bg-checkin-op1.jpg" alt="" />
        </div>
        <div className="img-logo">
          <img src="/images/logo.png" alt="" />
        </div>
        <div className="content-block mw-464">
          <Link to="/login" className='btn-back'>Back</Link>

          <div className="title mb-24">Forgot Password?</div>

          <div className="sum t-fz-16">
            No worries! Just enter your email or username and we'll send you a link to reset it.
          </div>

          <div className="btn-block btn-flex-2">
            {/* <Link to="/login/check-email" className="btn btn-send-phone-username h-48" type="primary">
              <div className="icon-svg"><ProfileCircle variant="Bulk" /></div>
              <div className="text">
                Phone number or username
              </div>
            </Link> */}
            <Input
              value={query}
              placeholder="Phone number or username"
              prefix={<ProfileCircle variant="Bulk" />}
              className='btn btn-send-phone-username h-48 rs-input'
              onChange={(e) => setQuery(e.target.value)}
            />
            <Button
              icon={<Magicpen variant="Bulk" size={24} />}
              disabled={query === ''}
              className="btn btn-send t-btn-primary h-48 cursor-pointer"
              onClick={sendReset}
            ><span className="text" style={{ color: '#0F0F0F' }}>Send reset link</span></Button>
            {/* <div className="btn btn-send t-btn-primary h-48 cursor-pointer" onClick={() => navigate('/login/check-email')}>
              <div className="icon-svg">
                <Magicpen variant="Bulk" />
              </div>
              <div className="text">
                Send reset link
              </div>
            </div> */}
          </div>
        </div>
      </div >
    </>
  );
}

export default ForgotPassword;
