import React, { useEffect, useState } from 'react'
import { Button, Flex, Form, FormProps, Input, InputNumber, notification, PaginationProps, Select, SelectProps, Table, TableColumnsType } from 'antd'
import { ArrowSquareDown, Home2, SearchZoomIn1, Shop } from 'iconsax-react'
import Layout from '../../components/layouts/Layout'
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock'
import { Link } from 'react-router-dom'
import { mixPostV1 } from '../../services/mixin'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'

const customPrevNext: PaginationProps['itemRender'] = (_, type, originalElement) => {
  if (type === 'prev') {
    return (
      <Flex align='center' gap={8}>
        <ArrowLeftOutlined />
        <span>Previous</span>
      </Flex>
    )
  }
  if (type === 'next') {
    return (
      <Flex align='center' gap={8}>
        <span>Next</span>
        <ArrowRightOutlined />
      </Flex>
    )
  }
  return originalElement
}

type LabelRender = SelectProps['labelRender'];
const customSelectedLabel: LabelRender = (props) => {
  const { label } = props;

  if (label) {
    return <Flex align='center' gap={8}><Shop size={20} variant="Bulk" style={{ minWidth: 20 }} /><span>{label}</span></Flex>;
  }
  return <Flex align='center' gap={8}><Shop size={20} variant="Bulk" style={{ minWidth: 20 }} /><span>Store</span></Flex>;
};

const columns: TableColumnsType = [
  {
    title: 'STT',
    dataIndex: 'index',
    key: 'index',
  },
  {
    title: 'Store Name',
    dataIndex: 'store_name',
    key: 'store_name',
  },
  {
    title: 'Name machine',
    dataIndex: 'device_name',
    key: 'device_name',
  },
  {
    title: 'ID Machine',
    dataIndex: 'device_id',
    key: 'device_id',
  },
  {
    title: 'Date',
    dataIndex: 'date_add',
    key: 'date_add',
  },
  {
    title: 'User add',
    dataIndex: 'owner_name',
    key: 'owner_name',
  },
]

const ListDevice:React.FC = () => {
  // GET STORES
  const user = JSON.parse(localStorage.getItem('user') || '{}')
  const [stores, setStores] = useState([] as any)

  let wait = false
  const getStores = async () => {
    if (wait === true) {
      return
    }
    wait = true
    try {
      const res = await mixPostV1('/api/webAdmin/gComboStoreByOwner.php', {owner_id: user.id})
      wait = false
      if (res.status === 1) {
        const result = res.data.map((item: any) => ({
          value: item.store_id.toString(),
          label: item.store_name,
        }))
        setStores(result)
      }
    } catch (error) { console.log(error) }
  }
  useEffect(() => {
    getStores()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // END GET STORES

  // GET LIST
  const [items, setItems] = useState({
    list: [],
    total: 0,
  })
  const [tableLoading, setTableLoading] = useState(false)

  const [params, setParams] = useState<{
    limit: number,
    page_number: number,
    machine_name?: string,
    store_id?: any,
  }>({
    limit: 10,
    page_number: 1,
    store_id: '',
  })

  const getItems = async () => {
    setTableLoading(true)

    try {
      const res = await mixPostV1('/api/webAdmin/gListAllDevicesForAdmin.php', params)

      if (res.status === 1) {
        const result:any = res.data.map((item: any, index: number) => ({
          ...item,
          index: index + 1,
        }))
        setItems({
          list: result,
          total: res.total,
        })
        setTableLoading(false)
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    getItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const onPageChange = (page: number) => {
    setParams({
      ...params,
      page_number: page,
    })
  }

  const onTableChange = (data: any) => {
    setParams({
      ...params,
      page_number: data.current,
      limit: data.pageSize,
    })
  }
  // END GET LIST

  // SEARCH
  const [searchTimeout, setSearchTimeout] = useState<any>(null)
  const onSearch = (query: string) => {
    setParams({
      ...params,
      page_number: 1,
      machine_name: query
    })
  }
  const onEnter = (e: any)  => {
    onSearch(e.target.value)
  }
  const onChange = (e: any) => {
    clearTimeout(searchTimeout)
    const timeout = setTimeout(() => {
      onSearch(e.target.value)
    }, 250)
    setSearchTimeout(timeout)
  }
  // END SEARCH

  // STORE CHANGE
  const onStoreChange = (value: any) => {
    if (value) {
      setParams({
        ...params,
        page_number: 1,
        store_id: value
      })
    } else {
      setParams({
        ...params,
        page_number: 1,
        store_id: ''
      })
    }
  }
  // END STORE CHANGE

  return (
    <>
      <Layout
        contentClassName="list-device"
      >
        <BreadcrumbBlock
          items={[
            { title: <Flex align='center' gap={16}><Home2 variant="Bulk" /><Link to="/">CMD Tech</Link></Flex>, },
            { title: 'List device', },
          ]}
          title='List device'
          description=''
        />

        <div className="table-filter-block rps-600-flex-column">
          <Select
            defaultValue={''}
            options={stores}
            placeholder="Store"
            popupMatchSelectWidth={false}
            allowClear={params.store_id !== ''}
            suffixIcon={<ArrowSquareDown size={20} variant="Bulk" />}
            className="rs-select w-186"
            labelRender={customSelectedLabel}
            onChange={onStoreChange}
          />

          <Input
            placeholder="Search by Name machine"
            prefix={<SearchZoomIn1 size={20} variant="Bulk" />}
            className="rs-input w-349"
            allowClear
            onPressEnter={(e) => onEnter(e)}
            onChange={(e) => onChange(e)}
          />
        </div>

        <Table
          columns={columns}
          dataSource={items.list}
          className="rs-table table-text-nowrap match-height"
          loading={tableLoading}
          rowKey='id'
          scroll={{
            x: true,
          }}
          pagination={{
            pageSize: params.limit,
            position: ['bottomCenter'],
            showSizeChanger: true,
            showTitle: false,
            total: items.total,
            itemRender: customPrevNext,
            onChange: (e) => onPageChange(e),
          }}
          onChange={onTableChange}
        />
      </Layout>
    </>
  )
}

export default ListDevice
