import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

// Analysis Report Pages
// import MarketingCampaigns from './pages/AnalysisReport/MarketingCampaigns'

//checkin
// import Checkin from './pages/Checkin'
import CheckLogin from './pages/Checkin/CheckLogin'
import MemberRegister from './pages/Checkin/MemberRegister'
import CheckinOTP from './pages/Checkin/CheckinOtp'
import CheckinOTPStaff from './pages/Checkin/CheckinOtpStaff'
import CheckinSetup from './pages/Checkin/CheckinSetup'
import CheckinSetupStaff from './pages/Checkin/CheckinSetupStaff'
import CheckinAi from './pages/Checkin/CheckinAi'
import CheckinAiStaff from './pages/Checkin/CheckinAiStaff'
import CheckinAiProgress from './pages/Checkin/CheckinAiProgress'
import NotificationSuccess from './pages/Checkin/NotificationSuccess'
import NotificationFail from './pages/Checkin/NotificationFail'
import NotificationLocked from './pages/Checkin/NotificationLocked'
import BuyPackages from './pages/Checkin/BuyPackages'
import BuySuccess from './pages/Checkin/BuySuccess'
import CheckinRole from './pages/Checkin/Role'
import CheckinAiProgressStaff from './pages/Checkin/CheckinAiProgressStaff'
import IdentityVerified from './pages/Checkin/IdentityVerified'
import CheckinSetUpStaffUpload from './pages/Checkin/CheckinSetUpStaffUpload'
import CheckinOtpDB from './pages/Checkin/CheckinOtpDB'
import CheckinChart from './pages/Checkin/CheckinChart'
import Login from './pages/Login/index'
import OtpLogin from './pages/Login/Otp'
import ForgotPassword from './pages/Login/ForgotPassword'
import CheckEmail from './pages/Login/CheckEmail'
import NewPassword from './pages/Login/NewPassword'
import ChooseStoreToGetDataRealTime from './pages/Login/ChooseStoreToGetDataRealTime'

// Staff
import StaffManage from './pages/Staff/Manage'
import StaffCashOut from './pages/Staff/Cashout'
import ReportDashboard from './pages/Report/Dashboard'
import ReportTotalIncome from './pages/Report/TotalIncome'
import ReportMachineIncome from './pages/Report/MachineIncome'
import ReportStaff from './pages/Report/Staff'
import Machine from './pages/Machine/Machine'
import Member from './pages/Member/Member'
import MemberCashOutNormal from './pages/Member/MemberCashOutNormal'
import Voucher from './pages/Voucher/Voucher'
import VoucherDetail from './pages/Voucher/VoucherDetail'
import LuckyGame from './pages/StoreGame/LuckyGame'
import RandomGame from './pages/StoreGame/RandomGame'
// import CreateNewStore from './pages/StoreManager/CreateNewStore'
import RequestStore from './pages/RequestStore/RequestStore'

// SETTING
import SettingDevice from './pages/Setting/Device'
import SettingShift from './pages/Setting/Shift'
import SettingMatchRules from './pages/Setting/MatchRules'
import SettingRolePermission from './pages/Setting/RolePermission'
import SettingRolePermissionAdd from './pages/Setting/RolePermission/Add'
import SettingRolePermissionEdit from './pages/Setting/RolePermission/Edit'
import SettingUserList from './pages/Setting/UserList'
import SettingMyAccount from './pages/Setting/MyAccount'
import SettingPaymentMethod from './pages/Setting/PaymentMethod'

// GENERAL
import PrivacyPolicy from './pages/General/PrivacyPolicy'

// ADMIN MANAGEMENT
import StoreList from './pages/StoreManager/StoreList'
import OwnerRequest from './pages/StoreManager/OwnerRequest'
import AccountManagerOwner from './pages/AccountManager/Owner'
import AccountManagerSeller from './pages/AccountManager/Seller'
import SalesReport from './pages/SalesReport/SalesReport'
import ListPayment from './pages/ListPayment/ListPayment'
import SettingType from './pages/AccountSetting/SettingType'
import ListDevice from './pages/AccountSetting/ListDevice'
import AccountSetting from './pages/AccountSetting/AccountSetting'

// Create Owner Account - User Account - Seller Account
import CreateUserAccount from './pages/CreateAccountFromMail/CreateUserAccount'
import CreateOwnerAccount from './pages/CreateAccountFromMail/CreateOwnerAccount'
import CreateSellerAccount from './pages/CreateAccountFromMail/CreateSellerAccount'

const App: React.FC = () => {
  const token = localStorage.getItem('token');
  // useEffect(() => {

  //   if (!token && window.location.pathname !== '/login') {
  //     window.location.href = '/login';
  //   } else if (token && window.location.pathname === '/login') {
  //     window.location.href = '/';
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  useEffect(() => {
    const { pathname } = window.location
    const parentPathName = pathname.split('/')[1]
    const notRequiredPages = ['login', 'privacy-policy', 'create-owner-account', 'create-user-account', 'create-seller-account']
    const notRequired = notRequiredPages.includes(parentPathName)

    if (!token) {
      if (!notRequired) {
        window.location.href = '/login'
      }
    } else if (token && pathname === '/login') {
      window.location.href = '/'
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Router>
      <Routes>

        {/* Analysis Report Pages */}
        {/* <Route path="/analysis-report/marketing-campaigns" element={<MarketingCampaigns />} /> */}
        {/* <Route path="/" element={<MarketingCampaigns />} /> */}

        {/* Checkin */}
        <Route path="/" element={<ReportDashboard />} />
        <Route path="/check-login" element={<CheckLogin />} />
        <Route path="/member-register" element={<MemberRegister />} />
        <Route path="/checkin-otp" element={<CheckinOTP />} />
        <Route path="/checkin/checkin-otp-staff" element={<CheckinOTPStaff />} />
        <Route path="/checkin-setup" element={<CheckinSetup />} />
        <Route path="/checkin/checkin-setup-staff" element={<CheckinSetupStaff />} />
        <Route path="/checkin-ai" element={<CheckinAi />} />
        <Route path="/checkin-ai-progress" element={<CheckinAiProgress />} />
        <Route path="/checkin/notification-success" element={<NotificationSuccess />} />
        <Route path="/checkin/notification-fail" element={<NotificationFail />} />
        <Route path="/checkin/notification-locked" element={<NotificationLocked />} />
        <Route path="/checkin/buy-packages" element={<BuyPackages />} />
        <Route path="/checkin/buy-success" element={<BuySuccess />} />
        <Route path="/checkin/role" element={<CheckinRole />} />
        <Route path="/checkin/checkin-ai-progress-staff" element={<CheckinAiProgressStaff />} />
        <Route path="/checkin/checkin-ai-staff" element={<CheckinAiStaff />} />
        <Route path="/checkin/identity-verified" element={<IdentityVerified />} />
        <Route path="/checkin/setup-staff-upload" element={<CheckinSetUpStaffUpload />} />
        <Route path="/checkin/checkin-otp-db" element={<CheckinOtpDB />} />
        <Route path="/checkin/chart" element={<CheckinChart />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login/otp" element={<OtpLogin />} />
        <Route path="/login/forgot-password" element={<ForgotPassword />} />
        <Route path="/login/check-email" element={<CheckEmail />} />
        <Route path="/login/new-password/:code" element={<NewPassword />} />
        <Route path="/login/choose-store" element={<ChooseStoreToGetDataRealTime />} />

        {/* Create Owner Account - User Account - Seller Account */}
        <Route path="/create-owner-account/:code" element={<CreateOwnerAccount />} />
        <Route path="/create-user-account/:code" element={<CreateUserAccount />} />
        <Route path="/create-seller-account/:code" element={<CreateSellerAccount />} />

        {/* Report */}
        <Route path="/report/dashboard" element={<ReportDashboard />} />
        <Route path="/report/total-income" element={<ReportTotalIncome />} />
        <Route path="/report/machine-income" element={<ReportMachineIncome />} />
        <Route path="/report/staff" element={<ReportStaff />} />

        {/* Machine */}
        <Route path="/machine" element={<Machine />} />

        {/* Staff */}
        <Route path="/staff/manage" element={<StaffManage />} />
        <Route path="/staff/cash-out" element={<StaffCashOut />} />

        {/* Member */}
        <Route path="/member" element={<Member />} />
        <Route path="/member/cash-out-normal" element={<MemberCashOutNormal />} />

        {/* Voucher */}
        <Route path="/voucher" element={<Voucher />} />
        <Route path="/voucher/:id" element={<VoucherDetail />} />

        {/* Store Game */}
        <Route path="/store-game/lucky-game" element={<LuckyGame />} />
        <Route path="/store-game/random-game" element={<RandomGame />} />

        {/* Setting */}
        <Route path="/setting" element={<SettingDevice />} />
        <Route path="/setting/device" element={<SettingDevice />} />
        <Route path="/setting/shift" element={<SettingShift />} />
        <Route path="/setting/match-rules" element={<SettingMatchRules />} />
        <Route path="/setting/role-permission" element={<SettingRolePermission />} />
        <Route path="/setting/role-permission/add" element={<SettingRolePermissionAdd />} />
        <Route path="/setting/role-permission/:id" element={<SettingRolePermissionEdit />} />
        <Route path="/setting/user-list" element={<SettingUserList />} />
        <Route path="/setting/my-account" element={<SettingMyAccount />} />
        <Route path="/setting/payment-method" element={<SettingPaymentMethod />} />

        {/* General */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/request-store" element={<RequestStore />} />

        {/* ADMIN MANAGEMENT */}
        <Route path="/store-list" element={<StoreList />} />
        <Route path="/store-list/owner-request" element={<OwnerRequest />} />
        <Route path="/account-list/owner" element={<AccountManagerOwner />} />
        <Route path="/account-list/seller" element={<AccountManagerSeller />} />
        <Route path="/sales-report" element={<SalesReport />} />
        <Route path="/list-payment" element={<ListPayment />} />
        <Route path="/setting-type" element={<SettingType />} />
        <Route path="/list-device" element={<ListDevice />} />
        <Route path="/account-setting" element={<AccountSetting />} />
      </Routes>
    </Router>
  )
}

export default App
