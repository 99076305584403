import React, { useEffect, useState, useRef } from 'react'
import './Game.scss'
import GameButton from './Button'

interface Props {
  userId?: string,
  userName?: string,
  isPlaying?: boolean,
  type?: string,
  players?: any,
  onPlay?: () => void
  onCancel?: () => void
  onConfirm?: any
  onPlayerClick?: () => void
  onResultClick?: () => void
}

const GameBar: React.FC<Props> = (props) => {
  const { isPlaying = false, type = 'lucky-game', onPlay, onCancel, onConfirm, onPlayerClick, onResultClick, players } = props
  const [gamePlaying, setGamePlaying] = useState(isPlaying)

  useEffect(() => {
    setGamePlaying(isPlaying)
  }, [isPlaying])

  const [listPlay, setListPlay] = useState([] as any)
  useEffect(()=>{
    const temp = [] as any
    players.map((val: any)=>{
      if (val.text) {
        temp.push(val)
      }
      return true
    })
    setListPlay(temp)
  }, [players])
  const [rf, setRf] = useState({} as any)

  const intervalIdRef = useRef(null as any);
  const [showCf, setShowCf] = useState(false)
  const onGamePlay = () => {
    let time = 0
    if (listPlay.length > 0) {
      setGamePlaying(true)
      handlePlayClick()
      let frtemp = null as any
      const rf0 = randIndex(listPlay, frtemp)
      frtemp = rf0
      setRf(rf0)
      intervalIdRef.current = setInterval(() => {
        time += 100
        const rf = randIndex(listPlay, frtemp)
        frtemp = rf
        setRf(rf)
        if (time >= 4000) {
          clearInterval(intervalIdRef.current)
          setShowCf(true)
        }
      }, 120)

      // if (rf) {
      //   setRf(randIndex(listPlay))
      //   if (onPlay) {
      //     setGamePlaying(true)
      //     onPlay()
      //   }
      // }
    }
  }

  const randIndex = (arr: any, rftemp: any) => {
    if (arr.length === 0) {
      return null
    }
    let newarr = arr
    if (arr.length > 1 && rftemp) {
      newarr = arr.filter((x: any) => x.id !== rftemp.id)
    }
    const randomIndex = Math.floor(Math.random() * newarr.length)
    return newarr[randomIndex]
  }

  const onGameCancel = () => {
    clearInterval(intervalIdRef.current)
    setGamePlaying(false)
    setRf({})
    setShowCf(false)
    if (audioRef.current) {
      audioRef.current.pause()
      audioRef.current.currentTime = 0
    }

    // if (onCancel) {
    //   setGamePlaying(false)
    //   onCancel()
    // }
  }

  const onGameConfirm = () => {
    if (onConfirm) {
      clearInterval(intervalIdRef.current)
      setGamePlaying(false)
      onConfirm(rf)
      setRf({})
      setShowCf(false)
    }
  }

  const audioRef = useRef(null as any);

  const handlePlayClick = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  return (
    <div className="game-bar">
      <audio ref={audioRef}>
        <source src="/sound01.mp3" type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
      <div className="game-bar__header" style={{ backgroundImage: 'url(/images/game-bar-header-bg.png)'}}>
        <img src="/images/favicon.svg" alt="CMD" className="game-bar__header-logo" />
      </div>

      <div className="game-bar__content">
        <div className="title">{ type === 'lucky-game' ? 'WINNER' : 'Result' }</div>

        <div className="announcement">
          <svg width="23" height="81" viewBox="0 0 23 81" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M14.5681 0.885864C10.7078 0.900532 7.00872 2.95103 4.27905 6.58934C1.54938 10.2277 0.0110043 15.1581 0 20.3034V61.4683C0.0110043 66.6137 1.54938 71.5441 4.27905 75.1824C7.00872 78.8207 10.7078 80.8712 14.5681 80.8859H21.3989C14.1329 79.8128 8.43188 71.5469 8.43188 61.5892V20.4243C8.44289 15.2789 9.98126 10.3485 12.7109 6.71019C15.4406 3.07187 19.1397 1.02138 23 1.00671" fill="url(#paint0_linear_562_10275)"/> <path d="M14.5681 0.885864C10.7078 0.900532 7.00872 2.95103 4.27905 6.58934C1.54938 10.2277 0.0110043 15.1581 0 20.3034V61.4683C0.0110043 66.6137 1.54938 71.5441 4.27905 75.1824C7.00872 78.8207 10.7078 80.8712 14.5681 80.8859H21.3989C14.1329 79.8128 8.43188 71.5469 8.43188 61.5892V20.4243C8.44289 15.2789 9.98126 10.3485 12.7109 6.71019C15.4406 3.07187 19.1397 1.02138 23 1.00671" fill="url(#paint1_linear_562_10275)" fillOpacity="0.8"/> <defs> <linearGradient id="paint0_linear_562_10275" x1="11.5" y1="-5.7808" x2="11.5" y2="114.219" gradientUnits="userSpaceOnUse"> <stop stopColor="#F9D361" stopOpacity="0.31"/> <stop offset="0.598958" stopColor="#F9D361"/> <stop offset="0.755208" stopColor="#B38600"/> <stop offset="0.854167" stopColor="#F9D361" stopOpacity="0"/> <stop offset="0.927083" stopColor="#F9D361" stopOpacity="0"/> </linearGradient> <linearGradient id="paint1_linear_562_10275" x1="11.5" y1="0.885865" x2="48.0876" y2="79.1217" gradientUnits="userSpaceOnUse"> <stop/> <stop offset="0.384993" stopOpacity="0"/> <stop offset="0.627157" stopOpacity="0.55"/> </linearGradient> </defs> </svg>

          <div className="announcement-text">
            {
              type === 'lucky-game' && (
                gamePlaying
                  ?
                    <>
                      <span className="name">Guy Hawkins</span>
                      <span className="id">G1241</span>
                    </>
                  :
                    <>
                      <span className="name">Winner name</span>
                      <span className="id">winner app id</span>
                    </>
              )
            }
            {
              type === 'random-game' && (
                gamePlaying
                  ? <span className="name">{ rf.text }</span>
                  : <span className="name">Title</span>
              )
            }
          </div>

          <svg width="23" height="81" viewBox="0 0 23 81" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M8.43189 0.885864C12.2922 0.900532 15.9913 2.95103 18.721 6.58934C21.4506 10.2277 22.989 15.1581 23 20.3034V61.4683C22.989 66.6137 21.4506 71.5441 18.721 75.1824C15.9913 78.8207 12.2922 80.8712 8.43189 80.8859H1.60115C8.86707 79.8128 14.5681 71.5469 14.5681 61.5892V20.4243C14.5571 15.2789 13.0187 10.3485 10.2891 6.71019C7.5594 3.07187 3.86033 1.02138 5.72205e-06 1.00671" fill="url(#paint0_linear_562_10278)"/> <path d="M8.43189 0.885864C12.2922 0.900532 15.9913 2.95103 18.721 6.58934C21.4506 10.2277 22.989 15.1581 23 20.3034V61.4683C22.989 66.6137 21.4506 71.5441 18.721 75.1824C15.9913 78.8207 12.2922 80.8712 8.43189 80.8859H1.60115C8.86707 79.8128 14.5681 71.5469 14.5681 61.5892V20.4243C14.5571 15.2789 13.0187 10.3485 10.2891 6.71019C7.5594 3.07187 3.86033 1.02138 5.72205e-06 1.00671" fill="url(#paint1_linear_562_10278)" fillOpacity="0.8"/> <defs> <linearGradient id="paint0_linear_562_10278" x1="11.5" y1="-5.7808" x2="11.5" y2="114.219" gradientUnits="userSpaceOnUse"> <stop stopColor="#F9D361" stopOpacity="0.31"/> <stop offset="0.598958" stopColor="#F9D361"/> <stop offset="0.755208" stopColor="#B38600"/> <stop offset="0.854167" stopColor="#F9D361" stopOpacity="0"/> <stop offset="0.927083" stopColor="#F9D361" stopOpacity="0"/> </linearGradient> <linearGradient id="paint1_linear_562_10278" x1="11.5" y1="0.885865" x2="-25.0876" y2="79.1217" gradientUnits="userSpaceOnUse"> <stop/> <stop offset="0.384993" stopOpacity="0"/> <stop offset="0.627157" stopOpacity="0.55"/> </linearGradient> </defs> </svg>
        </div>

        <div className="separator">
          <img src="/images/game-bar-separator.svg" alt="" />
        </div>

        <div className="controls">
          {
            !gamePlaying
              ?
                <GameButton
                  title='Play'
                  onClick={onGamePlay}
                />
              :
                <>
                  <GameButton
                    title='Cancel'
                    onClick={onGameCancel}
                  />
                  {
                    showCf === true &&
                    <GameButton
                      title='Confirm'
                      onClick={onGameConfirm}
                    />
                  }
                </>
            }
        </div>

        <div className="controls controls--2">
          <GameButton
            title='Show inputs'
            onClick={onPlayerClick}
          />
          <GameButton
            title='Show result'
            onClick={onResultClick}
          />
        </div>
      </div>

      <div className="game-bar__footer" style={{ backgroundImage: 'url(/images/game-bar-footer-bg.png)'}}></div>
    </div>
  )
}

export default GameBar
