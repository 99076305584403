import React from 'react'
import './SalesReportItem.scss'

interface Props {
  title?: string
  number?: number
}

const numberFormat = (number: any) => {
  let result
  if (typeof number === 'number') {
    result = new Intl.NumberFormat().format(number)
  } else {
    result = number
  }
  return result
}

const SalesReportItem:React.FC<Props> = (props) => {
  const { title, number } = props

  return (
    <div className="sales-report-item">
      <div className="title">{ title }</div>
      <div className="number">{ numberFormat(number) }</div>
    </div>
  )
}

export default SalesReportItem
