import React from "react";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

type dataType = {
  name: string,
  value: number,
  color: string,
}

interface Props {
  data?: dataType[]
}

const PieChart2: React.FC<Props> = (props) => {
  const { data } = props
  const chartData = data ? data : [{ name: '0~24', value: 61, color: '#cl4' },{ name: '25~49', value: 105, color: '#cl2' },{ name: '50~74', value: 100, color: '#cl3' },{ name: '75~99', value: 123, color: '#cl1' },{ name: '>100', value: 21, color: '#cl5' },]
  const total = chartData.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.value
  }, 0)

  const renderLegendText = (value: string, entry: any, index: number) => {
    const percentageValue = chartData[index].value ? ((chartData[index].value * 100) / total).toFixed(0) + "%" : '0%'
    return <span>{value} <span className="neutral-gray-400">({percentageValue})</span></span>
  }

  const renderLegend = (props: any) => {
    const { payload } = props;

    return (
      <ul className="custom-legend-render">
        <li className="custom-legend-render__total">{total} <span className="neutral-gray-400">Match:</span></li>
        {
          payload.map((entry: any, index: any) => {
            const percentageValue = chartData[index].value ? ((chartData[index].value * 100) / total).toFixed(0) + "%" : '0%'

            return <li key={`item-${index}`}>{entry.value} <span className="neutral-gray-400">({percentageValue})</span></li>
          })
        }
      </ul>
    )
  }

  return (
    <div className="arc-card">
      <div className="arc-card__content">
        <ResponsiveContainer height={234} className='pie-chart--desktop'>
          <PieChart
            width={160}
            // height={160}
            className="statistical-pie-chart rs-recharts-tooltip"
          >
            <defs>
              <linearGradient id="cl4" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#5282FF" stopOpacity={1} />
                <stop offset="100%" stopColor="#5282FF" stopOpacity={1} />
              </linearGradient>
              <linearGradient id="cl2" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#F9D361" stopOpacity={1} />
                <stop offset="100%" stopColor="#face4c" stopOpacity={1} />
              </linearGradient>
              <linearGradient id="cl3" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#D3ADF7" stopOpacity={1} />
                <stop offset="100%" stopColor="#cd9afd" stopOpacity={1} />
              </linearGradient>
              <linearGradient id="cl1" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#60f7f7" stopOpacity={1} />
                <stop offset="100%" stopColor="#4cf7f7" stopOpacity={1} />
              </linearGradient>
              <linearGradient id="cl5" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#ff5c52" stopOpacity={1} />
                <stop offset="100%" stopColor="#ff5c52" stopOpacity={1} />
              </linearGradient>
            </defs>

            <Pie
              data={data}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius="70%"
              outerRadius="100%"
              fill="url(#blue)"
              startAngle={180}
              endAngle={-180}
            >
              {
                chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={`url(${entry.color})`} />
                ))
              }
            </Pie>

            <Tooltip />
            <text
              x="27%"
              y="47%"
              textAnchor="middle"
              dominantBaseline="middle"
              className="number"
              fill="#fff"
              style={{ fontSize: "32px" }}
            >
              { total }
            </text>
            <text
              x="27%"
              y="58%"
              textAnchor="middle"
              dominantBaseline="middle"
              className="text"
              fill="#FFFFFF99"
            >
              Match
            </text>
            <Legend verticalAlign="middle" layout="vertical" iconType="square" align="right" formatter={renderLegendText} />
          </PieChart>
        </ResponsiveContainer>

        <ResponsiveContainer height={160} className='pie-chart--mobile'>
          <PieChart
            width={160}
            // height={160}
            className="statistical-pie-chart rs-recharts-tooltip"
          >
            <defs>
              <linearGradient id="cl4" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#5282FF" stopOpacity={1} />
                <stop offset="100%" stopColor="#5282FF" stopOpacity={1} />
              </linearGradient>
              <linearGradient id="cl2" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#F9D361" stopOpacity={1} />
                <stop offset="100%" stopColor="#face4c" stopOpacity={1} />
              </linearGradient>
              <linearGradient id="cl3" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#D3ADF7" stopOpacity={1} />
                <stop offset="100%" stopColor="#cd9afd" stopOpacity={1} />
              </linearGradient>
              <linearGradient id="cl1" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#60f7f7" stopOpacity={1} />
                <stop offset="100%" stopColor="#4cf7f7" stopOpacity={1} />
              </linearGradient>
              <linearGradient id="cl5" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#ff5c52" stopOpacity={1} />
                <stop offset="100%" stopColor="#ff5c52" stopOpacity={1} />
              </linearGradient>
            </defs>

            <Pie
              data={data}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius="70%"
              outerRadius="100%"
              fill="url(#blue)"
              startAngle={180}
              endAngle={-180}
            >
              {
                chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={`url(${entry.color})`} />
                ))
              }
            </Pie>

            <Tooltip />
            <Legend verticalAlign="middle" layout="vertical" iconType="square" align="right" content={renderLegend} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default PieChart2;
