import { Button, Flex, Form, Input, Select, SelectProps, DatePicker, Checkbox, FormInstance } from "antd";
import { AddSquare, ArrowSquareDown, CalendarAdd, EmojiHappy, EmojiSad, Magicpen, Calendar } from "iconsax-react";
import { useState, useRef, useEffect } from "react";
interface Props {
  matchSpecial?: any
  isSubmit?: any
  formFinish?: any
  formError?: any
  createDisabled?: boolean
  editDisabled?: boolean
  deleteDisabled?: boolean
}
const MatchInSpeacial: React.FC<Props> = (props) => {
  const { matchSpecial, isSubmit, formFinish, formError, createDisabled = false, editDisabled = false,  deleteDisabled = false } = props
  const formRef = useRef<FormInstance>(null)
  useEffect(() => {
    if (matchSpecial && matchSpecial.length > 0) {
      formRef?.current?.setFieldsValue({
        speacial: matchSpecial
      })
    }
  }, [matchSpecial])

  useEffect(() => {
    if (isSubmit && isSubmit > 0) {
      onSubmitForm()
    }
  }, [isSubmit])
  const onSubmitForm = () => {
    if (formRef && formRef.current) {
      formRef?.current?.submit()
    }
  }
  const onFinish = (values: any) => {
    const temp = [] as any
    if (values.speacial) {
      // eslint-disable-next-line array-callback-return
      values.speacial.map((item: any) => {
        temp.push({
          match_amount: item.match_amount,
          special_name: item.special_name,
          special_day: item.special_day.format('MM-DD'),
          is_active: item.is_active ? 1 : 0
        })
        // return true
      })
    }
    formFinish(temp)
  };

  const onError = (errors: any) => {
    if (formError) {
      formError(true)
    }
  }

  return (
    <div className="content collapse-shift">
      <div className="always-open mt-16">
        <Form
          ref={formRef}
          name="device-form"
          onFinish={onFinish}
          onFinishFailed={onError}
          autoComplete="off"
        >
          <Form.List name="speacial" >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (

                  <div className="one-input-time v2 one-2column-70-30" key={index}>
                    <div className="ctrl-left">
                      <div className="left">
                        <Form.Item
                          {...restField}
                          name={[name, 'special_day']}
                          key={`day-${key}`}
                          rules={[{ required: true, message: 'Please select day' }]}
                          className="w-100"
                        >
                          <DatePicker
                            format="MM-DD"
                            className='rs-date-picker v2'
                            popupClassName='rs-date-picker-dropdown'
                            style={{ width: '100%' }}
                            suffixIcon={<Calendar size={18} variant="Bulk"/>}
                            disabled={editDisabled}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'special_name']}
                          key={`name-${key}`}
                          rules={[{ required: true, message: 'Please input name match' }]}
                          className="w-100"
                        >
                          <Input
                            placeholder="Name match"
                            className="rs-input"
                            disabled={editDisabled}
                          />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, 'is_active']}
                          key={`active-${key}`}
                          valuePropName="checked"
                        >
                          <Checkbox
                            className="rs-checkbox"
                            disabled={editDisabled}
                          />
                        </Form.Item>
                      </div>
                      <div className="center">
                        <Form.Item
                          {...restField}
                          name={[name, 'match_amount']}
                          className="center"
                          key={key}
                          rules={[
                            { required: true, message: 'Please input match amount' },
                            {
                              type: 'number',
                              message: 'Please type number.',
                              transform(value) {
                                return Number(value)
                              },
                            },
                          ]}
                        >
                          <Input
                            placeholder="Maximum match"
                            className="rs-input"
                            disabled={editDisabled}
                            type="number"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="ctrl-right">
                      {/* <div
                        className={`right ${index !== 0 ? 'cl-FD6A6A' : ''}`}
                        onClick={() => {
                          if (index !== 0) remove(name)
                        }}
                      ><Magicpen size="20" variant="Bulk" /></div> */}
                      <div className={`right ${index !== 0 ? 'cl-FD6A6A' : ''}`}>
                        <Button
                          disabled={deleteDisabled}
                          icon={<Magicpen size="20" variant="Bulk" color={index !== 0 ? '#FD6A6A' : '#999999'} />}
                          onClick={() => {
                            if (index !== 0) {
                              remove(name)
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <Form.Item>
                  <Button
                    disabled={createDisabled}
                    className='btn-add-new'
                    onClick={() => add()}
                  >
                    <div className="icon-svg"><AddSquare size="20" color="#f9d361" variant="Bulk" /></div>
                    <div className="text">Add new</div>
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          {/* <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item> */}
        </Form>
      </div>
    </div>
  )
}

export default MatchInSpeacial
