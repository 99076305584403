import React, { useEffect, useState } from 'react'
import Layout from '../../components/layouts/Layout'
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Flex, Input, notification, PaginationProps, Progress, Table, TableColumnsType } from 'antd'
import { DocumentDownload, Home2, ReceiptDiscount, SearchZoomIn1 } from 'iconsax-react'
import { ArrowDownOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import CreateNewPopup from './CreateNewPopup'
import DeleteModal from '../../components/Modal/DeleteModal'
import './style.scss';
import EditPopup from './EditPopup'
import { mixPostV1 } from '../../services/mixin'
import * as XLSX from 'xlsx'

// interface DataType {
//   key: React.Key;
//   name: string,
//   total: number,
//   sms: number,
//   used: number,
//   usedpercent: number,
// }

const file = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.6667 1.66675H5.00004C4.55801 1.66675 4.13409 1.84234 3.82153 2.1549C3.50897 2.46746 3.33337 2.89139 3.33337 3.33341V16.6667C3.33337 17.1088 3.50897 17.5327 3.82153 17.8453C4.13409 18.1578 4.55801 18.3334 5.00004 18.3334H15C15.4421 18.3334 15.866 18.1578 16.1786 17.8453C16.4911 17.5327 16.6667 17.1088 16.6667 16.6667V6.66675M11.6667 1.66675L16.6667 6.66675M11.6667 1.66675V6.66675H16.6667M13.3334 10.8334H6.66671M13.3334 14.1667H6.66671M8.33337 7.50008H6.66671" stroke="#999999" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" /></svg>


const pencil = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clipPath="url(#clip0_1265_4186)"> <path d="M14.167 2.49993C14.3859 2.28106 14.6457 2.10744 14.9317 1.98899C15.2176 1.87054 15.5241 1.80957 15.8337 1.80957C16.1432 1.80957 16.4497 1.87054 16.7357 1.98899C17.0216 2.10744 17.2815 2.28106 17.5003 2.49993C17.7192 2.7188 17.8928 2.97863 18.0113 3.2646C18.1297 3.55057 18.1907 3.85706 18.1907 4.16659C18.1907 4.47612 18.1297 4.78262 18.0113 5.06859C17.8928 5.35455 17.7192 5.61439 17.5003 5.83326L6.25033 17.0833L1.66699 18.3333L2.91699 13.7499L14.167 2.49993Z" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" /> </g> <defs> <clipPath id="clip0_1265_4186"> <rect width="20" height="20" fill="white" /> </clipPath> </defs> </svg>

const trash = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.5 5.00008H4.16667M4.16667 5.00008H17.5M4.16667 5.00008V16.6667C4.16667 17.1088 4.34226 17.5327 4.65482 17.8453C4.96738 18.1578 5.39131 18.3334 5.83333 18.3334H14.1667C14.6087 18.3334 15.0326 18.1578 15.3452 17.8453C15.6577 17.5327 15.8333 17.1088 15.8333 16.6667V5.00008H4.16667ZM6.66667 5.00008V3.33341C6.66667 2.89139 6.84226 2.46746 7.15482 2.1549C7.46738 1.84234 7.89131 1.66675 8.33333 1.66675H11.6667C12.1087 1.66675 12.5326 1.84234 12.8452 2.1549C13.1577 2.46746 13.3333 2.89139 13.3333 3.33341V5.00008" stroke="#F5222D" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" /></svg>

const customPrevNext: PaginationProps['itemRender'] = (_, type, originalElement) => {
  if (type === 'prev') {
    return (
      <Flex align='center' gap={8}>
        <ArrowLeftOutlined />
        <span>Previous</span>
      </Flex>
    )
  }
  if (type === 'next') {
    return (
      <Flex align='center' gap={8}>
        <span>Next</span>
        <ArrowRightOutlined />
      </Flex>
    )
  }
  return originalElement
}

// CHECK ROLE = 'USER'
const user = JSON.parse(localStorage.getItem('user') || '{}')
const permission = user.permission
const memberRole = permission ? permission.find((item: any) => item.function_id === 4) : null

const Voucher: React.FC = () => {
  const navigator = useNavigate()
  const [showCreateNewPopup, setShowCreateNewPopup] = useState(false)
  const [showEditPopup, setShowEditPopup] = useState(false)
  const [showDeleteStaffPopup, setShowDeletePopup] = useState(false)
  const [deleteId, setDeleteId] = useState(0)
  const [dataEdit, setDataEdit] = useState({} as any)

  const columns: TableColumnsType = [
    {
      title: '',
      key: 'actions',
      fixed: 'left',
      className: 'actions-sticky actions-sticky--left',
      width: 144,
      render: (text, record) => (
        <Flex align='center' gap={4}>
          <Button
            icon={file}
            className="rs-button"
            disabled={memberRole && memberRole.is_view === 0}
            onClick={() => navigator(`/voucher/${record.voucher_id}`)}
          />
          <Button
            icon={trash}
            className="rs-button"
            disabled={memberRole && memberRole.is_delete === 0}
            onClick={() => {
              setShowDeletePopup(true)
              setDeleteId(record.voucher_id)
            }}
          />
          <Button
            icon={pencil}
            className="rs-button edit"
            disabled={memberRole && memberRole.is_edit === 0}
            onClick={() => {
              setShowEditPopup(true)
              setDataEdit(record)
            }}
          />
        </Flex>
      )
    },
    {
      title: 'Voucher Program',
      dataIndex: 'voucher_name',
      key: 'voucher_name',
      width: 214,
    },
    {
      title: 'Total',
      dataIndex: 'number_voucher',
      key: 'number_voucher',
      width: 214,
    },
    {
      title: 'Send SMS',
      dataIndex: 'sentsms',
      key: 'sentsms',
      width: 214,
    },
    {
      title: 'Send Content',
      dataIndex: 'sms_content',
      key: 'sms_content',
      width: 214,
      render: (value) => (
        <div style={{ minWidth: 214, maxWidth: 300, whiteSpace: 'normal' }}>{ value }</div>
      )
    },
    {
      title: 'Used',
      dataIndex: 'used',
      key: 'used',
      width: 214,
    },
    {
      title: 'Used percent',
      dataIndex: 'used_percent',
      key: 'used_percent',
      width: 214,
      align: 'right',
      // sorter: (a, b) => a.used_percent - b.used_percent,
      render: (text) => (
        <Progress className='ctrl-cl-progress' percent={text} size="small" />
      )
    }
  ]

  let waitx = false
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 } as PaginationProps)
  const [params, setParams] = useState({
    page_number: pagination.current,
    limit: pagination.pageSize,
    searchKey: '',
  } as any)
  const [listData, setListData] = useState([])
  const getList = async () => {
    if (waitx) {
      return
    }
    waitx = true
    const res = await mixPostV1('/api/webAdmin/gListVoucherStore.php', params)
    waitx = false
    if (res.status === 1) {
      setListData(res.data)
      setPagination({ ...pagination, total: res.total, pageSize: params.limit, current: params.page_number })
    }
  }

  useEffect(() => {
    getList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  let timeout = null as any
  const searchKeyChange = (value: string) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setParams({ ...params, page_number: 1, searchKey: value })
    }, 200);
  }

  // EXPORT EXCEL
  const getItemsByExcel = async () => {
    const customParams = {
      ...params,
      page_number: 1,
      limit: 10000,
    }

    try {
      const res = await mixPostV1('/api/webAdmin/gListVoucherStore.php', customParams)

      if (res.status) {
        if (res.data.length > 0) {
          formattedDataExcel(res.data)
        } else {
          openNotification('error', 'There is no data to export!')
        }
      } else {
        openNotification('error', 'Oops, something went wrong!')
      }
    } catch (error) {
      openNotification('error', 'Oops, something went wrong!')
    }
  }
  const formattedDataExcel = (data: any) => {
    const formattedData = data.map((item: any) => {
      return {
        'Voucher Program': item.voucher_name,
        'Total': item.number_voucher,
        'Send SMS': item.sentsms,
        'Send Content': item.sms_content,
        'Used': item.used,
        'Used Percent': item.used_percent,
      }
    })
    exportExcel(formattedData)
  }
  const exportExcel = (convertData: any): boolean => {
    try {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData)
      const workbook: XLSX.WorkBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Danh sách')

      const excelBuffer: ArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' })

      const saveAsExcel = (buffer: ArrayBuffer, fileName: string): void => {
        const data: Blob = new Blob([buffer], { type: 'application/octet-stream' })
        const link: HTMLAnchorElement = document.createElement('a')
        link.href = window.URL.createObjectURL(data)
        link.download = fileName
        link.click()
      }

      saveAsExcel(excelBuffer, `${"Voucher"}.xlsx`)
      return true // Export successful
    } catch (error) {
      console.error('Error exporting Excel:', error)
      return false // Export failed
    }
  }
  // END EXPORT EXCEL

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification()
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'Request success!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    })
  }

  return (
    <>
      { contextHolder }

      <Layout contentClassName="staff-manage-content">
        <BreadcrumbBlock
          items={[
            { title: <Flex align='center' gap={16}><Home2 variant="Bulk" /><Link to="/">CMD Tech</Link></Flex>, },
            { title: 'Voucher', },
          ]}
          title='Voucher'
          description=''
        />

        <div className="table-filter-block">
          <div className="left">
            <Input
              allowClear
              onChange={(e) => searchKeyChange(e.target.value)}
              placeholder="Search by name voucher"
              prefix={<SearchZoomIn1 size={20} variant="Bulk" />}
              className="rs-input table-filter-block__search"
            />
          </div>

          <div className="right">
            <Button
              icon={<DocumentDownload size={20} variant="Bulk" />}
              className="rs-button"
              onClick={getItemsByExcel}
            >Export</Button>

            <Button
              icon={<ReceiptDiscount size={20} variant="Bulk" />}
              className="rs-button"
              disabled={memberRole && memberRole.is_create === 0}
              onClick={() => setShowCreateNewPopup(true)}
            >Create new program</Button>
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={listData}
          className="rs-table text-nowrap t-table-member"
          scroll={{
            x: true,
          }}
          rowKey={'voucher_id'}
          showSorterTooltip={false}
          tableLayout="fixed"
          pagination={{
            position: ['bottomCenter'],
            pageSize: pagination.pageSize,
            current: pagination.current,
            total: pagination.total,
            onChange: (page, pageSize) => {
              setParams({ ...params, page_number: page, limit: pageSize })
            },
            showSizeChanger: true,
            showTitle: false,
            itemRender: customPrevNext,
          }}
        />

        <CreateNewPopup
          isShow={showCreateNewPopup}
          onPopupClose={() => {
            setShowCreateNewPopup(false)
            getList()
          }}
        />

        <EditPopup
          isShow={showEditPopup}
          dataEdit={dataEdit}
          onPopupClose={() => {
            setShowEditPopup(false)
            getList()
          }}
        />

        <DeleteModal
          isShow={showDeleteStaffPopup}
          content='Are you sure you want to delete this program voucher?'
          message='Voucher deleted!'
          deleteApi='/api/webAdmin/deleteVoucherById.php'
          deleteId={deleteId}
          deleteKey='voucher_id'
          onModalClose={() => setShowDeletePopup(false)}
          onModalSubmit={() => {
            setShowDeletePopup(false)
            getList()
          }}
        />
      </Layout>
    </>
  )
}

export default Voucher
