import React, { useEffect, useState } from 'react'
import Layout from '../../components/layouts/Layout'
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock'
import { Badge, Button, Flex, PaginationProps, Table, TableColumnsType } from 'antd'
import { Home2 } from 'iconsax-react'
import { Link } from 'react-router-dom'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import RequestNewStorePopup from './RequestNewStorePopup'
import { mixPostV1 } from '../../services/mixin'

const customPrevNext: PaginationProps['itemRender'] = (_, type, originalElement) => {
  if (type === 'prev') {
    return (
      <Flex align='center' gap={8}>
        <ArrowLeftOutlined />
        <span>Previous</span>
      </Flex>
    )
  }
  if (type === 'next') {
    return (
      <Flex align='center' gap={8}>
        <span>Next</span>
        <ArrowRightOutlined />
      </Flex>
    )
  }
  return originalElement
}

const columns: TableColumnsType = [
  {
    title: '',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'Store Name',
    dataIndex: 'storeName',
    key: 'storeName',
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
  },
  {
    title: 'Type',
    dataIndex: 'type_id',
    key: 'type_id',
    render: (type_id, record) => (
      // <Badge status={storeType(type_id).color} text={ record.type_name } className='rs-badge background' />
      <Badge status={type_id === 1 ? 'warning' : 'default'} text={ record.type_name } className='rs-badge background' />
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status_name',
    key: 'status_name',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Reason',
    dataIndex: 'reason',
    key: 'reason',
  },
]

const RequestStore:React.FC = () => {
  // const [tableLoading, setTableLoading] = useState(false)
  const [showRequestNewStorePopup, setShowRequestNewStorePopup] = useState(false)

  // GET LIST
  const [items, setItems] = useState({
    list: [],
    total: 0,
    commission: 0,
    income: 0,
    store: 0,
    seller: 0,
  })
  const [tableLoading, setTableLoading] = useState(false)

  const [params, setParams] = useState<{
    limit: number,
    page_number: number,
  }>({
    limit: 10,
    page_number: 1,
  })

  const getItems = async () => {
    setTableLoading(true)

    try {
      const res = await mixPostV1('/api/webAdmin/gListRequestStoreOwner.php', params)

      if (res.status === 1) {
        const result:any = res.data.map((item: any) => ({
          ...item,
          key: item.store_id,
          storeName: item.store_name,
          time: item.create_at,
          type: item.type_name,
          reason: item.reason_reject,
        }))
        setItems({
          ...items,
          list: result,
          total: res.total,
        })
        setTableLoading(false)
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    getItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const onPageChange = (page: number) => {
    setParams({
      ...params,
      page_number: page,
    })
  }

  const onTableChange = (data: any) => {
    setParams({
      ...params,
      page_number: data.current,
      limit: data.pageSize,
    })
  }
  // END GET LIST

  return (
    <Layout contentClassName="staff-manage-content">
      <BreadcrumbBlock
        items={[
          { title: <Flex align='center' gap={16}><Home2 variant="Bulk" /><Link to="/">CMD Tech</Link></Flex>, },
          { title: 'Request Store', },
        ]}
        title='Request Store'
        description=''
      />

      <div className="table-filter-block">
        <Button
          // icon={<UserAdd size={20} variant="Bulk"/>}
          className="rs-button mis-auto"
          onClick={() => setShowRequestNewStorePopup(true)}
        >Request New Store</Button>
      </div>

      <Table
        columns={columns}
        dataSource={items.list}
        className="rs-table table-text-nowrap match-height"
        loading={tableLoading}
        scroll={{
          x: true,
        }}
        pagination={{
          pageSize: params.limit,
          position: ['bottomCenter'],
          showSizeChanger: true,
          showTitle: false,
          total: items.total,
          itemRender: customPrevNext,
          onChange: (e) => onPageChange(e),
        }}
        onChange={onTableChange}
      />

      <RequestNewStorePopup
        isShow={showRequestNewStorePopup}
        onPopupClose={() => setShowRequestNewStorePopup(false)}
        onSuccess={() => {
          setShowRequestNewStorePopup(false)
          getItems()
        }}
      />
    </Layout>
  )
}

export default RequestStore
