import React from 'react';
// import { Helmet } from "react-helmet";
import './style.scss';
import { Link } from 'react-router-dom';

const CheckLogin: React.FC = () => {

  return (
    <div className='checkin-page checkin-index'>
      <div className="bg-img">
        <img src="/images/checkin/bg-checkin-op1.jpg" alt="" />
      </div>
      <div className="content-block mw-502">
        <div className="title">Ready for a New Journey?</div>
        <div className="sum">
          We're excited to have <br /> you here.
        </div>
        <div className="btn-block">
          <Link to="/checkin-ai" className="btn t-btn-primary">Check-in</Link>
          <Link to="/member-register" className="btn t-btn-df">Register</Link>
        </div>
      </div>
    </div>
  );
}

export default CheckLogin;
