import React from 'react';
// import { Helmet } from "react-helmet";
import './style.scss';
import { Link } from 'react-router-dom';

const CheckinSetup: React.FC = () => {


  return (
    <div className='checkin-page checkin-setup'>
      <div className="bg-img">
        <img src="/images/checkin/bg-checkin-op1.jpg" alt="" />
      </div>
      <div className="content-block mw-828">
        <div className="title mb-24">Setup eKYC</div>

        <div className="sum">
          Quick & easy
        </div>

        <div className="list-setup">
          <div className="one-item">
            <div className="number">1</div>
            <div className="text">Put your face in the frame</div>
          </div>
          <div className="one-item">
            <div className="number">2</div>
            <div className="text">Follow the instructions on the screen</div>
          </div>
        </div>

        <div className="btn-block ctrl-grid-2">
          <Link to="/check-login" className="btn btn-border-text-primary h-68">Cancel</Link>
          <div className="mb-0">
            <Link to="/checkin-ai" className="btn t-btn-primary h-68" type="primary">
              <div className="text">
                Check-in now
              </div>
            </Link>
          </div>
        </div>

      </div>
    </div>
  );
}

export default CheckinSetup;
