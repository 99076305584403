import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../components/layouts/Layout'
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock'
import { Button, DatePicker, Flex, GetProps, Input, PaginationProps, Table, TableColumnsType, notification } from 'antd'
import { Calendar, DocumentDownload, Home2, SearchZoomIn1 } from 'iconsax-react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import './SalesReport.scss'
import SalesReportItem from './SalesReportItem'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { mixPostV1 } from '../../services/mixin'
import * as XLSX from 'xlsx'
// import type { Dayjs } from 'dayjs'
// import tableData from './tableData.json'

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>
dayjs.extend(customParseFormat)
const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  // Can not select days after today and today
  return current && current > dayjs().endOf('day')
}

const numberFormat = (number: any) => {
  let result
  if (typeof number === 'number') {
    result = new Intl.NumberFormat().format(number)
  } else {
    result = number
  }
  return result
}

const customPrevNext: PaginationProps['itemRender'] = (_, type, originalElement) => {
  if (type === 'prev') {
    return (
      <Flex align='center' gap={8}>
        <ArrowLeftOutlined />
        <span>Previous</span>
      </Flex>
    )
  }
  if (type === 'next') {
    return (
      <Flex align='center' gap={8}>
        <span>Next</span>
        <ArrowRightOutlined />
      </Flex>
    )
  }
  return originalElement
}

const metricsFilter = [
  { id: 'today', label: 'Today' },
  { id: 'weekly', label: 'Weekly' },
  { id: 'month', label: 'Monthly' },
  { id: 'year', label: 'Yearly' },
]

const SalesReport:React.FC = () => {
  const [metricFilterSelected, setMetricFilterSelected] = useState('all')
  const [selectedDate, setSelectedDate] = useState(null)

  const onMetricFilter = (id: string) => {
    let time = 'all'
    if (metricFilterSelected !== 'all' && metricFilterSelected === id) {
      setMetricFilterSelected('all')
      time = 'all'
    } else {
      setMetricFilterSelected(id)
      time = id
    }
    setSelectedDate(null)
    setParams({
      ...params,
      time: time,
    })
  }

  // TABLE
  interface tableColumnType {
    key: React.Key
    sellerId: number
    sellerName: string
    phoneNumber: string
    income: number
    commission: number
    storeName: string
    ownerName: string
  }
  const columns: TableColumnsType<tableColumnType> = [
    {
      title: 'Seller ID',
      dataIndex: 'sellerId',
      key: 'sellerId',
    },
    {
      title: 'Seller name',
      dataIndex: 'sellerName',
      key: 'sellerName',
    },
    {
      title: 'Income ($)',
      dataIndex: 'income',
      key: 'income',
      render: (value) => (
        <span>{ numberFormat(value) }</span>
      )
    },
    {
      title: 'Commission ($)',
      dataIndex: 'commission',
      key: 'commission',
      render: (value) => (
        <span>{ numberFormat(value) }</span>
      )
    },
    {
      title: 'Store name',
      dataIndex: 'storeName',
      key: 'storeName',
    },
    {
      title: 'Owner name',
      dataIndex: 'ownerName',
      key: 'ownerName',
    },
    {
      title: 'Phone number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
  ]
  // END TABLE

  // GET LIST
  const [items, setItems] = useState({
    list: [],
    total: 0,
    commission: 0,
    income: 0,
    store: 0,
    seller: 0,
  })
  const [tableLoading, setTableLoading] = useState(false)

  const [params, setParams] = useState<{
    limit: number,
    page_number: number,
    searchKey?: string,
    time?: string,
  }>({
    limit: 10,
    page_number: 1,
    time: metricFilterSelected
  })

  const getItems = async () => {
    setTableLoading(true)

    try {
      const apiParams = {
        ...params
      }
      if (!apiParams.time || apiParams.time === '') {
        apiParams.time = 'all'
      }
      const res = await mixPostV1('/api/webAdmin/salesReport.php', apiParams)

      if (res.status === 1) {
        const result:any = res.data.map((item: any, index: number) => ({
          key: index,
          sellerId: item.seller_id,
          sellerName: item.seller_name,
          phoneNumber: item.phone_number,
          income: item.income,
          commission: item.commission,
          storeName: item.store_name,
          ownerName: item.owner_name,
        }))
        setItems({
          ...items,
          list: result,
          total: res.totalRecord,
          commission: res.commission,
          income: res.income,
          store: res.totalStore,
          seller: res.totalSale,
        })
        setTableLoading(false)
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    getItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const onPageChange = (page: number) => {
    setParams({
      ...params,
      page_number: page,
    })
  }

  const onTableChange = (data: any) => {
    setParams({
      ...params,
      page_number: data.current,
      limit: data.pageSize,
    })
  }
  // END GET LIST

  // SEARCH
  const [searchTimeout, setSearchTimeout] = useState<any>(null)
  const onSearch = (query: string) => {
    setParams({
      ...params,
      page_number: 1,
      searchKey: query,
    })
  }
  const onEnter = (e: any)  => {
    onSearch(e.target.value)
  }
  const onChange = (e: any) => {
    clearTimeout(searchTimeout)
    const timeout = setTimeout(() => {
      onSearch(e.target.value)
    }, 250)
    setSearchTimeout(timeout)
  }

  // GET DATA BY DATE
  const getDataByDate = (date: any, dateString: any) => {
    setMetricFilterSelected('')
    setSelectedDate(date)
    setParams({
      ...params,
      page_number: 1,
      time: dateString,
    })
  }
  // END GET DATE BY DATE

  // EXPORT EXCEL
  const getItemsByExcel = async () => {
    const customParams = {
      ...params,
      page_number: 1,
      limit: 10000,
      time: metricFilterSelected || 'all'
    } as any

    try {
      const res = await mixPostV1('/api/webAdmin/salesReport.php', customParams)

      if (res.status) {
        if (res.data.length > 0) {
          formattedDataExcel(res.data)
        } else {
          openNotification('error', 'There is no data to export!')
        }
      } else {
        openNotification('error', 'Oops, something went wrong!')
      }
    } catch (error) {
      openNotification('error', 'Oops, something went wrong!')
    }
  }
  const formattedDataExcel = (data: any) => {
    const formattedData = data.map((item: any) => {
      return {
        'Seller ID': item.seller_id,
        'Seller Name': item.seller_name,
        'Phone number': item.phone_number,
        'Income ($)': item.income,
        'Commission ($)': item.commission,
        'Store name': item.store_name,
        'Owner name': item.owner_name,
      }
    })
    exportExcel(formattedData)
  }
  const exportExcel = (convertData: any): boolean => {
    try {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData)
      const workbook: XLSX.WorkBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Danh sách')

      const excelBuffer: ArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' })

      const saveAsExcel = (buffer: ArrayBuffer, fileName: string): void => {
        const data: Blob = new Blob([buffer], { type: 'application/octet-stream' })
        const link: HTMLAnchorElement = document.createElement('a')
        link.href = window.URL.createObjectURL(data)
        link.download = fileName
        link.click()
      }

      saveAsExcel(excelBuffer, `${"Sales Report"}.xlsx`)
      return true // Export successful
    } catch (error) {
      console.error('Error exporting Excel:', error)
      return false // Export failed
    }
  }
  // END EXPORT EXCEL

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification()
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'Request success!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    })
  }

  // EXPORT PDF
  const componentRef = useRef() as any
  const [loadingExportPdf, setLoadingExportPdf] = useState(false)
  const handleDownloadPdf = () => {
    setLoadingExportPdf(true)
    const input = componentRef.current
    html2canvas(input, {
      scale: 2, // Tăng độ phân giải của canvas để đảm bảo chất lượng cao hơn
      useCORS: true, // Cho phép cross-origin images
    }).then(canvas => {
      const imgData = canvas.toDataURL('image/png')
      const pdf = new jsPDF('p', 'mm', 'a4')

      // Tính toán kích thước của hình ảnh trên PDF
      const imgWidth = 210 // Chiều rộng của A4 là 210mm
      const pageHeight = 295 // Chiều cao của A4 là 295mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width
      let heightLeft = imgHeight
      let position = 0

      // Chèn hình ảnh vào PDF và xử lý nếu hình ảnh dài hơn một trang
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
      heightLeft -= pageHeight

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight
        pdf.addPage()
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
        heightLeft -= pageHeight
      }

      pdf.save('Sales Report.pdf')
      setLoadingExportPdf(false)
    })
  }

  return (
    <>
      { contextHolder }

      <Layout contentClassName="sales-report-content">
        <BreadcrumbBlock
          items={[
            { title: <Flex align='center' gap={16}><Home2 variant="Bulk" /><Link to="/">CMD Tech</Link></Flex>, },
            { title: 'Sales report' },
          ]}
          title='Sales report'
          description=''
        />

        <Flex
          ref={componentRef}
          vertical gap={18}
        >
          <Flex
            align='center'
            gap={24}
            className='d-colums-767'
          >
            <Flex align='center' gap={12} className='metric-list'>
              {
                metricsFilter.map(item => (
                  <div
                    key={item.id}
                    className={`metric-item ${metricFilterSelected === item.id ? 'active' : ''}`}
                    onClick={() => {
                      onMetricFilter(item.id)
                    }}
                  >{ item.label }</div>
                ))
              }
            </Flex>

            <DatePicker
              value={selectedDate}
              allowClear
              placeholder='Select date'
              className='rs-date-picker'
              popupClassName='rs-date-picker-dropdown'
              suffixIcon={<Calendar size={18} variant="Bulk"/>}
              disabledDate={disabledDate}
              onChange={getDataByDate}
            />
            <Button
              icon={<DocumentDownload size={20} variant="Bulk"/>}
              className="rs-button mis-auto"
              loading={loadingExportPdf}
              onClick={handleDownloadPdf}
            >Export PDF</Button>
          </Flex>

          <div
            className="sales-report-list"
          >
            <SalesReportItem
              title='Seller'
              number={items.seller}
            />
            <SalesReportItem
              title='Store'
              number={items.store}
            />
            <SalesReportItem
              title='Income (USD)'
              number={items.income}
            />
            <SalesReportItem
              title='Commission (USD)'
              number={items.commission}
            />
          </div>

          <Flex align='center' gap={16}>
            <Input
              placeholder="Search by seller name"
              prefix={<SearchZoomIn1 size={20} variant="Bulk" />}
              className="rs-input w-full"
              allowClear
              onPressEnter={(e) => onEnter(e)}
              onChange={(e) => onChange(e)}
            />
            <Button
              icon={<DocumentDownload size={20} variant="Bulk" />}
              className="rs-button h-[40px] w-[102px]"
              onClick={getItemsByExcel}
            >Export</Button>
          </Flex>

          <Table
            columns={columns}
            dataSource={items.list}
            className="rs-table table-text-nowrap match-height"
            loading={tableLoading}
            scroll={{
              x: true,
            }}
            pagination={{
              current: params.page_number,
              pageSize: params.limit,
              position: ['bottomCenter'],
              showSizeChanger: true,
              showTitle: false,
              total: items.total,
              itemRender: customPrevNext,
              onChange: (e) => onPageChange(e),
            }}
            onChange={onTableChange}
          />
        </Flex>
      </Layout>
    </>
  )
}

export default SalesReport
