import React, { useEffect, useState } from 'react'
import Layout from '../../components/layouts/Layout'
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock'
import { Link } from 'react-router-dom'
import { Button, Flex, Input, PaginationProps, Table, TableColumnsType, DatePicker, notification } from 'antd'
import { Calendar2, DocumentDownload, Home2, SearchZoomIn1 } from 'iconsax-react'
import './style.scss';
import { ArrowLeftOutlined, ArrowRightOutlined, LineOutlined, } from '@ant-design/icons'
import { mixPostV1 } from '../../services/mixin'
import * as XLSX from 'xlsx'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { RangePickerProps } from 'antd/es/date-picker'

const { RangePicker } = DatePicker

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  // Can not select days before today and today
  return current && current > dayjs().endOf('day')
}

const numberFormat = (number: any) => {
  let result
  if (typeof number === 'number') {
    result = new Intl.NumberFormat().format(number)
  } else {
    result = number
  }
  return result
}

interface DataType {
  key: React.Key;
  date: string;
  name: string;
  staff_name: string;
  shift: string;
  totalin: string;
  totalout: string;
  profit: number;
  statusprofit: number;
}

const customPrevNext: PaginationProps['itemRender'] = (_, type, originalElement) => {
  if (type === 'prev') {
    return (
      <Flex align='center' gap={8}>
        <ArrowLeftOutlined />
        <span>Previous</span>
      </Flex>
    )
  }
  if (type === 'next') {
    return (
      <Flex align='center' gap={8}>
        <span>Next</span>
        <ArrowRightOutlined />
      </Flex>
    )
  }
  return originalElement
}

const columns: TableColumnsType<DataType> = [
  {
    title: 'Date',
    dataIndex: 'date',
  },
  {
    title: 'Machine name',
    dataIndex: 'name',
  },
  {
    title: 'Staff',
    dataIndex: 'staff_name',
  },
  {
    title: 'Total in (USD)',
    dataIndex: 'totalin',
    align: 'right',
    render: (text) => <span>{ numberFormat(text) }</span>
  },
  {
    title: 'Total out (USD)',
    dataIndex: 'totalout',
    align: 'right',
    render: (text) => <span>{ numberFormat(text) }</span>
  },
  {
    title: 'Profit (USD)',
    dataIndex: 'profit',
    align: 'right',
    render: (text) => (
      <div className='ctrl-td-profit'>
        <span className={`${Number(text) >= 0 ? 'status-1' : 'status-2'}`}>{ text }</span>
      </div>
    ),
  },
];

const ReportTotalIncome: React.FC = () => {
  // GET LIST
  const [items, setItems] = useState({
    list: [],
    total: 0,
  })
  const [tableLoading, setTableLoading] = useState(false)
  const [finalTotal, setFinalTotal] = useState<{ dateReport: string, totalIn: number, totalOut: number, profit: number}>({ dateReport: '', totalIn: 0, totalOut: 0, profit: 0})

  const [params, setParams] = useState<{
    limit: number,
    page_number: number,
    searchKey?: string,
    fromDate?: string,
    toDate?: string,
    searchMachineName?: string,
  }>({
    limit: 10,
    page_number: 1,
  })

  const getItems = async () => {
    setTableLoading(true)
    // const res = await mixPostV1('/api/webAdmin/reportTotalIncome.php', params)
    const res = await mixPostV1('/api/webAdmin/reportMachineIncome.php', params)

    if (res.status === 1) {
      const result:any = []
      res.data.map((item: any, index: number) => result.push({
        ...item,
        key: index,
        date: item.date_report,
        profit: item.profit,
        name: item.machineIdName,
        totalin: item.total_in,
        totalout: item.total_out,
      }))
      const total = Object.keys(res.totalRecord).length === 0 ? res.totalRecord : res.totalRecord.totalRecord
      setItems({
        ...items,
        list: result,
        total: total,
      })
      setTableLoading(false)
      setFinalTotal(res.finalTotal)
    }
  }

  useEffect(() => {
    getItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const onPageChange = (page: number) => {
    setParams({
      ...params,
      page_number: page,
    })
  }

  const onTableChange = (data: any) => {
    setParams({
      ...params,
      page_number: data.current,
      limit: data.pageSize,
    })
  }
  // END GET LIST

  // SEARCH
  const [searchTimeout, setSearchTimeout] = useState<any>(null)
  const onSearch = (query: string) => {
    setParams({
      ...params,
      page_number: 1,
      searchMachineName: query,
    })
  }
  const onEnter = (e: any)  => {
    onSearch(e.target.value)
  }
  const onChange = (e: any) => {
    clearTimeout(searchTimeout)
    const timeout = setTimeout(() => {
      onSearch(e.target.value)
    }, 250)
    setSearchTimeout(timeout)
  }

  // On Date Change
  const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    if (dates) {
      setParams({
        ...params,
        page_number: 1,
        fromDate: dateStrings[0],
        toDate: dateStrings[1],
      })
    } else {
      setParams({
        ...params,
        page_number: 1,
        fromDate: '',
        toDate: '',
      })
    }
  };
  // END SEARCH

  // EXPORT EXCEL
  const getItemsByExcel = async () => {
    const customParams = {
      ...params,
      page_number: 1,
      limit: 10000,
    }

    try {
      const res = await mixPostV1('/api/webAdmin/reportMachineIncome.php', customParams)

      if (res.status === 1) {
        if (res.data.length > 0) {
          formattedDataExcel(res.data)
        } else {
          openNotification('error', 'There is no data to export!')
        }
      } else {
        openNotification('error', 'Oops, something went wrong!')
      }
    } catch (error) {
      openNotification('error', 'Oops, something went wrong!')
    }
  }
  const formattedDataExcel = (data: any) => {
    const formattedData = data.map((item: any) => {
      return {
        'Date': item.date_report,
        'Machine name': item.machineIdName,
        'Staff': item.staff_name,
        'Total in (USD)': item.total_in,
        'Total out (USD)': item.total_out,
        'Profit (USD)': item.profit,
      }
    })
    exportExcel(formattedData)
  }
  const exportExcel = (convertData: any): boolean => {
    try {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData)
      const workbook: XLSX.WorkBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Danh sách')

      const excelBuffer: ArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' })

      const saveAsExcel = (buffer: ArrayBuffer, fileName: string): void => {
        const data: Blob = new Blob([buffer], { type: 'application/octet-stream' })
        const link: HTMLAnchorElement = document.createElement('a')
        link.href = window.URL.createObjectURL(data)
        link.download = fileName
        link.click()
      }

      saveAsExcel(excelBuffer, `${"Machine Income Report"}.xlsx`)
      return true // Export successful
    } catch (error) {
      console.error('Error exporting Excel:', error)
      return false // Export failed
    }
  }
  // END EXPORT EXCEL

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification()
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'Request success!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    })
  }

  return (
    <>
      { contextHolder }

      <Layout contentClassName="report-machine-income-content">
        <BreadcrumbBlock
          items={[
            { title: <Flex align='center' gap={16}><Home2 variant="Bulk" /><Link to="/">CMD Tech</Link></Flex>, },
            { title: 'Report', },
            { title: 'Machine Income', },
          ]}
          title='Machine Income'
          description=''
        />

        <div className="table-filter-block">
          <Input
            placeholder="Search by name"
            prefix={<SearchZoomIn1 size={20} variant="Bulk" />}
            className="rs-input t-input-ctrl table-filter-block__search"
            allowClear
            onPressEnter={(e) => onEnter(e)}
            onChange={(e) => onChange(e)}
          />

          <RangePicker
            allowClear
            className="rs-range-picker"
            suffixIcon={<Calendar2 size={20} />}
            separator={<LineOutlined />}
            placeholder={['From date', 'To date']}
            disabledDate={disabledDate}
            onChange={onRangeChange}
          />

          <Button
            icon={<DocumentDownload size={20} variant="Bulk" />}
            className="rs-button mis-auto t-h40"
            onClick={getItemsByExcel}
          >Export</Button>
        </div>

        <div className="t-table-block">
          <Table
            columns={columns}
            dataSource={items.list}
            className="rs-table table-text-nowrap match-height"
            showSorterTooltip={false}
            loading={tableLoading}
            scroll={{ x: true }}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>Final Total <br /> { finalTotal.dateReport ? `(${finalTotal.dateReport})` : '' }</Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>{ numberFormat(finalTotal.totalIn) }</Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>{ numberFormat(finalTotal.totalOut) }</Table.Summary.Cell>
                  <Table.Summary.Cell index={5} className='t-total-table'>{ numberFormat(finalTotal.profit) }</Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
            pagination={{
              current: params.page_number,
              pageSize: params.limit,
              position: ['bottomCenter'],
              showSizeChanger: true,
              showTitle: false,
              total: items.total,
              itemRender: customPrevNext,
              onChange: (e) => onPageChange(e),
            }}
            onChange={onTableChange}
          />
        </div>
      </Layout>
    </>
  )
}

export default ReportTotalIncome
