import Layout from '../../components/layouts/Layout'
import BreadcrumbDetailBlock from '../../components/BreadcrumbBlock/BreadcrumbDetailBlock'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { Avatar, Button, Flex, Input, notification, PaginationProps, Table, TableColumnsType } from 'antd'
import { DocumentDownload, Home2, SearchZoomIn1 } from 'iconsax-react'
import { ArrowDownOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import './style.scss';
import { mixPostV1 } from '../../services/mixin'
import { useEffect, useState } from 'react'
import * as XLSX from 'xlsx'


const columns: TableColumnsType = [
  {
    title: 'Voucher Code',
    dataIndex: 'voucher_code',
    key: 'voucher_code',
    width: 304,
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center" }}>
        Status <ArrowDownOutlined style={{ marginLeft: "8px" }} />
      </div>
    ),
    dataIndex: 'voucher_status',
    key: 'voucher_status',
    width: 304,
    sorter: (a, b) => a.status - b.status,
    render: (text) => (
      <>
        {/* {
          text === 1 && (
            <div className="voucher-type-status type-1">Sended SMS</div>
          )
        }
        {
          text === 2 && (
            <div className="voucher-type-status type-2">Used</div>
          )
        }
        {
          text === 3 && (
            <div className="voucher-type-status type-3">New</div>
          )
        } */}
        <div className="voucher-type-status type-1">{ text }</div>
      </>
    )
  },
  {
    title: 'Customer',
    dataIndex: 'customer',
    key: 'customer',
    width: 304,
    render: (text, record, index) => (
      <div className="voucher-user-info">
        {/* <div className="ing-box">
          <img src={record.imgcustomer} alt="img" />
        </div> */}
        <Avatar src={record.customer_avatar} size={40} alt={record.customer_name} />
        <div className="name-id-voucher">
          <div className="name">{record.customer_name}</div>
          <div className="iduser">App ID {record.customer_id}</div>
        </div>
      </div>
    )
  },
  {
    title: 'Staff gives vouchers ',
    dataIndex: 'staff',
    key: 'staff',
    width: 304,
    render: (text, record, index) => (
      <div className="voucher-user-info">
        {/* <div className="ing-box">
          <img src={record.imgstaff} alt="img" />
        </div> */}
        <Avatar src={record.staff_avatar} size={40} alt={record.staff_name} />
        <div className="name-id-voucher">
          <div className="name">{record.staff_name}</div>
          <div className="iduser">App ID {record.staff_id}</div>
        </div>
      </div>
    )
  }
]

const customPrevNext: PaginationProps['itemRender'] = (_, type, originalElement) => {
  if (type === 'prev') {
    return (
      <Flex align='center' gap={8}>
        <ArrowLeftOutlined />
        <span>Previous</span>
      </Flex>
    )
  }
  if (type === 'next') {
    return (
      <Flex align='center' gap={8}>
        <span>Next</span>
        <ArrowRightOutlined />
      </Flex>
    )
  }
  return originalElement
}

const VoucherDetail: React.FC = () => {
  const pathParams = useParams()
  const navigator = useNavigate()

  // GET LIST
  const [items, setItems] = useState({
    list: [],
    total: 0,
  })
  const [tableLoading, setTableLoading] = useState(false)

  const [params, setParams] = useState<{
    voucher_id: number,
    limit: number,
    page_number: number,
    searchKey?: string,
  }>({
    voucher_id: Number(pathParams.id),
    limit: 10,
    page_number: 1,
  })

  const getItems = async () => {
    setTableLoading(true)

    try {
      const res = await mixPostV1('/api/webAdmin/gListVoucherProgramDetail.php', params)

      if (res.status === 1) {
        const result:any = []
        res.data.map((item: any) => result.push({
          ...item,
        }))
        setItems({
          ...items,
          list: result,
          total: res.total,
        })
        setTableLoading(false)
      } else {
        navigator('/voucher')
      }
    } catch (error) {
      navigator('/voucher')
    }
  }

  useEffect(() => {
    getItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const onPageChange = (page: number) => {
    setParams({
      ...params,
      page_number: page,
    })
  }

  const onTableChange = (data: any) => {
    setParams({
      ...params,
      page_number: data.current,
      limit: data.pageSize,
    })
  }
  // END GET LIST

  // SEARCH
  const onSearch = (query: string) => {
    setParams({
      ...params,
      searchKey: query,
    })
  }
  const onEnter = (e: any)  => {
    onSearch(e.target.value)
  }
  const onChange = (e: any) => {
    if (e.target.value === '') {
      onSearch('')
    }
  }
  // END SEARCH

  // EXPORT EXCEL
  const getItemsByExcel = async () => {
    const customParams = {
      ...params,
      page_number: 1,
      limit: 10000,
    }

    try {
      const res = await mixPostV1('/api/webAdmin/gListVoucherProgramDetail.php', customParams)

      if (res.status) {
        if (res.data.length > 0) {
          formattedDataExcel(res.data)
        } else {
          openNotification('error', 'There is no data to export!')
        }
      } else {
        openNotification('error', 'Oops, something went wrong!')
      }
    } catch (error) {
      openNotification('error', 'Oops, something went wrong!')
    }
  }
  const formattedDataExcel = (data: any) => {
    const formattedData = data.map((item: any) => {
      return {
        'Voucher Code': item.voucher_code,
        'Status': item.voucher_status,
        'Customer ID': item.customer_id,
        'Customer Name': item.customer_name,
        'Staff ID': item.staff_id,
        'Staff Name': item.staff_name,
      }
    })
    exportExcel(formattedData)
  }
  const exportExcel = (convertData: any): boolean => {
    try {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData)
      const workbook: XLSX.WorkBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Danh sách')

      const excelBuffer: ArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' })

      const saveAsExcel = (buffer: ArrayBuffer, fileName: string): void => {
        const data: Blob = new Blob([buffer], { type: 'application/octet-stream' })
        const link: HTMLAnchorElement = document.createElement('a')
        link.href = window.URL.createObjectURL(data)
        link.download = fileName
        link.click()
      }

      saveAsExcel(excelBuffer, `${"Voucher detail"}.xlsx`)
      return true // Export successful
    } catch (error) {
      console.error('Error exporting Excel:', error)
      return false // Export failed
    }
  }
  // END EXPORT EXCEL

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification()
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'Request success!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    })
  }

  return (
    <>
      { contextHolder }

      <Layout contentClassName="staff-manage-content">
        <BreadcrumbDetailBlock
          items={[
            { title: <Flex align='center' gap={16}><Home2 variant="Bulk" /><Link to="/">CMD Tech</Link></Flex>, },
            { title: 'Voucher Program Detail', },
          ]}
          title='Voucher Program Detail'
          backLink="/voucher"
          description=''
        />

        <div className="table-filter-block">
          <div className="left">
            <Input
              placeholder="Search"
              prefix={<SearchZoomIn1 size={20} variant="Bulk" />}
              className="rs-input t-input-ctrl mw-349px"
              allowClear
              onPressEnter={(e) => onEnter(e)}
              onChange={(e) => onChange(e)}
            />
          </div>

          <div className="right">
            <Button
              icon={<DocumentDownload size={20} variant="Bulk" />}
              className="rs-button"
              disabled={items.total === 0}
              onClick={getItemsByExcel}
            >Export</Button>
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={items.list}
          className="rs-table text-nowrap t-table-member"
          loading={tableLoading}
          scroll={{
            x: true,
          }}
          showSorterTooltip={false}
          tableLayout="fixed"
          pagination={{
            pageSize: params.limit,
            position: ['bottomCenter'],
            showSizeChanger: true,
            showTitle: false,
            total: items.total,
            itemRender: customPrevNext,
            onChange: (e) => onPageChange(e),
          }}
          onChange={onTableChange}
        />
      </Layout>
    </>
  )
}

export default VoucherDetail
