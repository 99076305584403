import React, { useEffect, useState } from 'react'
import Layout from '../../components/layouts/Layout'
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock'
import { Link } from 'react-router-dom'
import { Button, Flex, Input, notification, PaginationProps, Table, TableColumnsType } from 'antd'
import { DocumentDownload, Home2, SearchZoomIn1, UserAdd } from 'iconsax-react'
import { ArrowLeftOutlined, ArrowRightOutlined, FileOutlined } from '@ant-design/icons'
import LockModal from '../../components/Modal/LockModal'
// import tableData from './Seller/seller-table-data.json'
import './style.scss';
import CreateNewSellerPopup from './Seller/CreateNewSellerPopup'
import EditSeller from './Seller/EditSeller'
import DetailSeller from './Seller/DetailSeller'
import { mixPostV1 } from '../../services/mixin'
import * as XLSX from 'xlsx'

interface DataType {
  key: React.Key;
  sellerName: string,
  sellerId: number,
  phoneNumber: string,
  email: string,
  statusLock: number,
  id: number,
}

const pencil = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clipPath="url(#clip0_1265_4186)"> <path d="M14.167 2.49993C14.3859 2.28106 14.6457 2.10744 14.9317 1.98899C15.2176 1.87054 15.5241 1.80957 15.8337 1.80957C16.1432 1.80957 16.4497 1.87054 16.7357 1.98899C17.0216 2.10744 17.2815 2.28106 17.5003 2.49993C17.7192 2.7188 17.8928 2.97863 18.0113 3.2646C18.1297 3.55057 18.1907 3.85706 18.1907 4.16659C18.1907 4.47612 18.1297 4.78262 18.0113 5.06859C17.8928 5.35455 17.7192 5.61439 17.5003 5.83326L6.25033 17.0833L1.66699 18.3333L2.91699 13.7499L14.167 2.49993Z" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" /> </g> <defs> <clipPath id="clip0_1265_4186"> <rect width="20" height="20" fill="white" /> </clipPath> </defs> </svg>

const lock = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 15V12.5M13.125 9.375V5C13.125 3.27411 11.7259 1.875 10 1.875C8.27411 1.875 6.875 3.27411 6.875 5V9.375M3.75 16.875V10.625C3.75 9.93464 4.30964 9.375 5 9.375H15C15.6904 9.375 16.25 9.93464 16.25 10.625V16.875C16.25 17.5654 15.6904 18.125 15 18.125H5C4.30964 18.125 3.75 17.5654 3.75 16.875Z" stroke="#F5222D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></svg>

const unlock = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 15V12.5M13.125 5C13.125 3.27411 11.7259 1.875 10 1.875C8.27411 1.875 6.875 3.27411 6.875 5V9.375M3.75 16.875V10.625C3.75 9.93464 4.30964 9.375 5 9.375H15C15.6904 9.375 16.25 9.93464 16.25 10.625V16.875C16.25 17.5654 15.6904 18.125 15 18.125H5C4.30964 18.125 3.75 17.5654 3.75 16.875Z" stroke="#0CAF60" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></svg>

const customPrevNext: PaginationProps['itemRender'] = (_, type, originalElement) => {
  if (type === 'prev') {
    return (
      <Flex align='center' gap={8}>
        <ArrowLeftOutlined />
        <span>Previous</span>
      </Flex>
    )
  }
  if (type === 'next') {
    return (
      <Flex align='center' gap={8}>
        <span>Next</span>
        <ArrowRightOutlined />
      </Flex>
    )
  }
  return originalElement
}

const AccountManagerSeller: React.FC = () => {
  const [showCreateNewSellerPopup, setShowCreateNewSellerPopup] = useState(false)
  const [showEditSeller, setShowEditSeller] = useState(false)
  const [showLockPopup, setShowLockPopup] = useState(false)
  const [showDetailPopup, setDetailPopup] = useState(false)
  const [selectedId, setSelectedId] = useState<number>()
  const [selectedItem, setSelectedItem] = useState<any>()

  const columns: TableColumnsType<DataType> = [
    {
      title: '',
      key: 'actions',
      fixed: 'left',
      className: 'actions-sticky actions-sticky--left',
      width: 160,
      render: (text, record, index) => (
        <Flex align='center' gap={4}>
          <Button
            icon={<FileOutlined />}
            className="rs-button"
            onClick={() => {
              setSelectedId(record.id)
              // setSelectedItem(record)
              setDetailPopup(true)
            }}
          />
          <Button
            icon={record.statusLock === 1 ? lock : unlock}
            className="rs-button"
            onClick={() => {
              setSelectedItem(record)
              setShowLockPopup(true)
            }}
          />
          {/* {
            record.statusLock === 1 && (
              <Button icon={lock} className="rs-button" onClick={() => setShowLockPopup(true)}></Button>
            )
          }
          {
            record.statusLock === 0 && (
              <Button icon={unlock} className="rs-button" onClick={() => setShowLockPopup(true)}></Button>
            )
          } */}
          <Button
            icon={pencil}
            className="rs-button edit"
            onClick={() => {
              setSelectedId(record.id)
              // setSelectedItem(record)
              setShowEditSeller(true)
            }}
          />
        </Flex>
      )
    },
    {
      title: 'Seller name',
      dataIndex: 'sellerName',
      key: 'sellerName',
    },
    {
      title: 'Seller ID',
      dataIndex: 'sellerId',
      key: 'sellerId',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
  ]

  // GET SELLERS
  const [items, setItems] = useState({
    list: [],
    total: 0,
  })
  const [tableLoading, setTableLoading] = useState(false)

  const [params, setParams] = useState({
    limit: 10,
    page_number: 1,
    searchKey: '',
  })

  const getItems = async () => {
    setTableLoading(true)
    const res = await mixPostV1('/api/webAdmin/gListAccountSeller.php', params)

    if (res.status === 1) {
      const result:any = []
      res.data.map((item: any) => result.push({
        ...item,
        key: item.id,
        sellerId: item.id,
        sellerName: item.owner_name,
        phoneNumber: item.phone_number,
        email: item.email,
        statusLock: item.is_locked,
      }))
      setItems({
        ...items,
        list: result,
        total: res.total,
      })
      setTableLoading(false)
    }
  }

  useEffect(() => {
    getItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const onPageChange = (page: number) => {
    setParams({
      ...params,
      page_number: page,
    })
  }

  const onTableChange = (data: any) => {
    setParams({
      ...params,
      page_number: data.current,
      limit: data.pageSize,
    })
  }
  // END GET SELLERS

  // SEARCH
  const [searchTimeout, setSearchTimeout] = useState<any>(null)
  const onSearch = (e: any)  => {
    setParams({
      ...params,
      page_number: 1,
      searchKey: e.target.value
    })
  }
  const onChange = (e: any) => {
    clearTimeout(searchTimeout)
    const timeout = setTimeout(() => {
      onSearch(e)
    }, 250)
    setSearchTimeout(timeout)
  }
  // END SEARCH

  // EXPORT EXCEL
  const getItemsByExcel = async () => {
    const customParams = {
      ...params,
      page_number: 1,
      limit: 10000,
    }
    // const res = await mixPostV1('/api/webAdmin/gListAccountSeller.php', customParams)

    // if (res.status) {
    //   formattedDataExcel(res.data)
    // }
    try {
      const res = await mixPostV1('/api/webAdmin/gListAccountSeller.php', customParams)

      if (res.status) {
        if (res.data.length > 0) {
          formattedDataExcel(res.data)
        } else {
          openNotification('error', 'There is no data to export!')
        }
      } else {
        openNotification('error', 'Oops, something went wrong!')
      }
    } catch (error) {
      openNotification('error', 'Oops, something went wrong!')
    }
  }
  const formattedDataExcel = (data: any) => {
    const formattedData = data.map((item: any) => {
      return {
        'Seller Name': item.owner_name,
        'Seller ID': item.id,
        'Email': item.email,
        'Phone Number': item.phone_number,
      }
    })
    exportExcel(formattedData)
  }
  const exportExcel = (convertData: any): boolean => {
    try {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData)
      const workbook: XLSX.WorkBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Danh sách')

      const excelBuffer: ArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' })

      const saveAsExcel = (buffer: ArrayBuffer, fileName: string): void => {
        const data: Blob = new Blob([buffer], { type: 'application/octet-stream' })
        const link: HTMLAnchorElement = document.createElement('a')
        link.href = window.URL.createObjectURL(data)
        link.download = fileName
        link.click()
      }

      saveAsExcel(excelBuffer, `${"Seller list"}.xlsx`)
      return true // Export successful
    } catch (error) {
      console.error('Error exporting Excel:', error)
      return false // Export failed
    }
  }
  // END EXPORT EXCEL

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification()
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'Request success!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    })
  }

  return (
    <>
      { contextHolder }

      <Layout contentClassName="account-manager-seller-content">
        <BreadcrumbBlock
          items={[
            { title: <Flex align='center' gap={16}><Home2 variant="Bulk" /><Link to="/">CMD Tech</Link></Flex>, },
            { title: 'Account List', },
            { title: 'Seller', },
          ]}
          title='Seller'
          description=''
        />

        <div className="table-filter-block">
          <Input
            placeholder="Search by phone number, name"
            prefix={<SearchZoomIn1 size={20} variant="Bulk" />}
            allowClear
            className="rs-input table-filter-block__search"
            onChange={(e) => onChange(e)}
            onPressEnter={(e) => onSearch(e)}
          />

          <Button
            icon={<DocumentDownload size={20} variant="Bulk" />}
            className="rs-button"
            onClick={getItemsByExcel}
          >Export</Button>

          <Button
            icon={<UserAdd size={20} variant="Bulk" />}
            className="rs-button"
            onClick={() => setShowCreateNewSellerPopup(true)}
          >Create new seller</Button>
        </div>

        <Table
          columns={columns}
          dataSource={items.list}
          className="rs-table table-text-nowrap match-height"
          loading={tableLoading}
          scroll={{
            x: true,
          }}
          pagination={{
            current: params.page_number,
            pageSize: params.limit,
            position: ['bottomCenter'],
            showSizeChanger: true,
            showTitle: false,
            total: items.total,
            itemRender: customPrevNext,
            onChange: (e) => onPageChange(e),
          }}
          onChange={onTableChange}
        />

        <LockModal
          isShow={showLockPopup}
          content={`Are you sure you want to ${selectedItem?.is_locked === 1 ? 'accept' : 'lock'} this seller?`}
          message='Seller updated!'
          apiUrl='/api/webAdmin/updateBlockOwner.php'
          param={{owner_id: selectedItem?.id, is_locked: selectedItem?.is_locked === 1 ? 0 : 1}}
          type={selectedItem?.is_locked === 1 ? 'unlock' : 'lock'}
          onModalClose={() => setShowLockPopup(false)}
          onModalSubmit={() => {
            getItems()
            setShowLockPopup(false)
          }}
        />

        <CreateNewSellerPopup
          isShow={showCreateNewSellerPopup}
          onPopupClose={() => setShowCreateNewSellerPopup(false)}
          onSuccess={getItems}
        />

        <EditSeller
          id={selectedId}
          isShow={showEditSeller}
          onPopupClose={() => setShowEditSeller(false)}
          onSuccess={getItems}
        />

        <DetailSeller
          id={selectedId}
          isShow={showDetailPopup}
          onPopupClose={() => setDetailPopup(false)}
          onSuccess={getItems}
        />
      </Layout>
    </>
  )
}

export default AccountManagerSeller
