const changeValx = (list: any, valx: any) => {
  const obs = {} as any
  list.map((val: any) => {
    obs[`s${val.value}`] = val
    return true
  })
  if (valx || valx === 0) {
    return obs[`s${valx}`] || obs['sdf']
  }
  return list
}
export const storeStatus = (valx: any) => {
  const list = [
    {
      value: 'df',
      color: 'default'
    },
    {
      value: 0,
      color: 'processing'
    },
    {
      value: 1,
      color: 'success',
    },
    {
      value: 2,
      color: 'warning',
    },
    {
      value: 3,
      color: 'warning',
    },
    {
      value: 4,
      color: 'error',
    }
  ]
  return changeValx(list, valx)
}

export const storeType = (valx: any) => {
  const list = [
    {
      value: 'df',
      color: 'default'
    },
    {
      value: 1,
      color: 'error'
    },
    {
      value: 2,
      color: 'success',
    },
    {
      value: 3,
      color: 'warning',
    },
    {
      value: 4,
      color: 'processing',
    }
  ]
  return changeValx(list, valx)
}

export const serviceTypes = [
  { value: 3, label: 'Match reading' },
  { value: 4, label: 'Match only' },
  { value: 2, label: 'Match door' },
  { value: 1, label: 'Full services' },
]

export const shifts = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
]
