import React from 'react'
import './EmptyResult.scss'

const GameEmptyResult: React.FC = () => {
  return (
    <div className="game-empty-result">
      <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M60 120C93.1371 120 120 93.1371 120 60C120 26.8629 93.1371 0 60 0C26.8629 0 0 26.8629 0 60C0 93.1371 26.8629 120 60 120Z" fill="#262626"/> <path d="M96 120H24V42.4C27.3936 42.3962 30.6471 41.0464 33.0468 38.6468C35.4464 36.2471 36.7962 32.9936 36.8 29.6H83.2C83.1964 31.2811 83.5262 32.9462 84.1704 34.499C84.8146 36.0518 85.7603 37.4615 86.9528 38.6464C88.1377 39.8392 89.5474 40.7853 91.1004 41.4296C92.6533 42.0739 94.3187 42.4037 96 42.4V120Z" fill="white"/> <path d="M59.9994 81.5999C70.6032 81.5999 79.1994 73.0038 79.1994 62.3999C79.1994 51.796 70.6032 43.1999 59.9994 43.1999C49.3955 43.1999 40.7994 51.796 40.7994 62.3999C40.7994 73.0038 49.3955 81.5999 59.9994 81.5999Z" fill="#98A2B3"/> <path d="M66.7875 71.4509L59.9993 64.6627L53.211 71.4509L50.9483 69.1881L57.7365 62.3999L50.9483 55.6117L53.211 53.349L59.9993 60.1372L66.7875 53.349L69.0502 55.6117L62.262 62.3999L69.0502 69.1881L66.7875 71.4509Z" fill="white"/> <path d="M70.3994 86.4H49.5994C48.2739 86.4 47.1994 87.4745 47.1994 88.8C47.1994 90.1255 48.2739 91.2 49.5994 91.2H70.3994C71.7249 91.2 72.7994 90.1255 72.7994 88.8C72.7994 87.4745 71.7249 86.4 70.3994 86.4Z" fill="#EAECF0"/> <path d="M77.5996 95.9997H42.3996C41.0742 95.9997 39.9996 97.0742 39.9996 98.3997C39.9996 99.7252 41.0742 100.8 42.3996 100.8H77.5996C78.9251 100.8 79.9996 99.7252 79.9996 98.3997C79.9996 97.0742 78.9251 95.9997 77.5996 95.9997Z" fill="#EAECF0"/> </svg>
      <div className="title-16 text-idle">Game not started</div>
    </div>
  )
}

export default GameEmptyResult
