import React from 'react'
import './GameLayout.scss'

interface Props {
  players?: React.ReactNode,
  game?: React.ReactNode,
  results?: React.ReactNode,
}

const GameLayout: React.FC<Props> = (props) => {
  const { players, game, results } = props

  return (
    <div className="game-layout">
      { players }
      { game }
      { results }
    </div>
  )
}

export default GameLayout
